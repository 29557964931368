import Page from '../js/page.js';
import * as pageManager from '@trullock/page-manager';
import * as rocketRepository from '../js/rocket-repository.js'
import { showConfirm, showToast } from '../js/utils.js'
import PageShowError from '@trullock/page-manager/pageshowerror'

export default pageManager.registerPage({'rockets-view': '/rockets/{rocketId}'}, class extends Page {
	static requireAuth = true;

	constructor($page) {
		super($page);

		this.$rocketName = $page.$('.js-rocket-name');
		this.$rocketBuildType = $page.$('.js-rocket-build-type');
		this.$rocketDiameter = $page.$('.js-rocket-diameter');
		this.$rocketLength = $page.$('.js-rocket-length');
		this.$rocketNotes = $page.$('.js-rocket-notes');
		this.$btnAddFlight = $page.$('.js-btn-add-flight');
		this.$btnDeleteRocket = $page.$('.js-btn-delete')

		this.$flights = $page.$('.js-flights-container');
		this.$template = this.$flights.firstElementChild.remove();
		this.$noFlights = $page.$('.js-no-flights');
		

		
		this.$btnAddFlight.addClickListener(() => {
			pageManager.navigate(pageManager.getPath('rockets-flights-add', { rocketId: this.data.rocket.id }));
		});

		this.$btnDeleteRocket.addClickListener(() => {
			showConfirm({
				title: 'Confirm delete',
				message: 'Are you sure you want to delete the rocket "' + this.data.rocket.name + '" and all associated flights?',
				positiveText: 'Delete',
				positiveStyle: 'danger',
				positiveAction: () => {
					this.unsub();
					return rocketRepository
						.deleteRocket(this.data.rocket.id)
						.then(() => pageManager.navigate(pageManager.getPath('root')));
				}
			})
		});
	}

	get title() {
		return this.data.rocket.name;
	}

	boot(opts){
		return rocketRepository
			.getRocketWithFlights(opts.rocketId, (rocket, flights) => this.renderRocket({ rocket, flights }))
			.then(unsub => this.unsub = unsub);
	}

	destroy() {
		this.unsub?.();
	}
	
	renderRocket(data)
	{
		this.data = data;

		if(data.rocket == null)
			throw new PageShowError(pageManager.getPath('root'), 'Cannot access rocket');


		this.$rocketName.textContent = data.rocket.name;
		this.$rocketBuildType.textContent = data.rocket.buildType;
		this.$rocketDiameter.textContent = data.rocket.diameter;
		this.$rocketLength.textContent = data.rocket.length;
		if (data.rocket.notes)
			this.$rocketNotes.textContent = data.rocket.notes;
		else
			this.$rocketNotes.remove();

		this.$noFlights.classList.toggle('d-none', data.flights.length > 0);

		this.$flights.empty();
		for (let f = 0; f < data.flights.length; f++) {
			let flight = data.flights[f];
			let $flight = this.$template.cloneNode(true);
			let date = new Date();
			date.setTime(flight.createdOn);

			$flight.$('.js-date').textContent = date.toLocaleDateString();
			$flight.$('.js-date').setAttribute('datetime', date.toISOString());
			$flight.$('.js-location').textContent = flight.location || 'Unknown';
			$flight.$('.js-motor').textContent = flight.motor ? (flight.motor + (flight.delay ? ' - ' + flight.delay + 's' : '')) : 'No motor specified';
			
			let $outcomeIcon = $flight.$('.js-outcome-icon');
			if (flight.outcome)
			{
				$flight.classList.add('border-left-' + (flight.outcome == 'positive' ? 'success' : flight.outcome == 'negative' ? 'danger' : 'secondary'));
				$outcomeIcon.classList.add(flight.outcome == 'positive' ? 'text-success' : flight.outcome == 'negative' ? 'text-danger' : 'text-secondary');
			} else {
				$outcomeIcon.remove();
			}
			
			$flight.$('.js-outcome-message').textContent = !flight.outcome ? '' : flight.outcome == 'positive' ? 'Positive' : flight.outcome == 'mediocre' ? 'Mediocre' : 'Negative';
			if (flight.notes)
				$flight.$('.js-notes').textContent = flight.notes;
			else
				$flight.$('.js-notes').remove();

			let $btnSetOutcome = $flight.$('.js-btn-outcome');
			if (flight.outcome)
			{
				$btnSetOutcome.classList.add('d-none');
			} else {
				$btnSetOutcome.href = pageManager.getPath('rockets-flights-outcome', { rocketId: this.data.rocket.id, flightId: flight.id });
			}

			if (flight.certification)
			{
				let $cert = $flight.$('.js-cert');
				$cert.classList.add('bg-' + (!flight.certificationOutcome ? 'secondary' : flight.certificationOutcome == 'positive' ? 'success' : 'danger'));
				$cert.$('.js-level').textContent = 'Level ' + flight.certification;
			} else {
				$flight.$('.js-cert').remove();
			}

			$flight.$('.js-btn-flight-edit').addClickListener(() => {
				return pageManager.navigate(pageManager.getPath('rockets-flights-edit', { rocketId: this.data.rocket.id, flightId: flight.id }), { flight: flight });
			});

			$flight.$('.js-btn-flight-set-outcome').addClickListener(() => {
				return pageManager.navigate(pageManager.getPath('rockets-flights-outcome', { rocketId: this.data.rocket.id, flightId: flight.id }), { flight: flight });
			});

			$flight.$('.js-btn-flight-copy').addClickListener(() => {
				return pageManager.navigate(pageManager.getPath('rockets-flights-add', { rocketId: this.data.rocket.id }), { flight: flight });
			});

			$flight.$('.js-btn-flight-delete').addClickListener(() => {
				rocketRepository
					.deleteFlight(this.data.rocket.id, flight.id)
					.then(_ => {
						showToast({
							style: 'success',
							message: 'Flight has been deleted',
							buttons: [
								{
									text: 'Undo',
									action: () => {
										rocketRepository.saveFlight(this.data.rocket.id, flight);
									}
								}
							]
						});
					})
			});

			//$row.$('a').href = getPath('rockets-view', { rocketId: rockets[r].id })
			this.$flights.appendChild($flight);
		}
	}
})