import Page from '../js/page.js';
import * as pageManager from '@trullock/page-manager';
import * as rocketRepository from '../js/rocket-repository.js'
import * as locationRepository from '../js/location-repository.js'
import {motors} from '../js/motors.js'
import TomSelect from 'tom-select'

export default pageManager.registerPage({
		'rockets-flights-add': '/rockets/{rocketId}/flights/add',
		'rockets-flights-edit': '/rockets/{rocketId}/flights/{flightId}/edit'
	}, class extends Page {
	static requireAuth = true;
	static htmlName = 'rockets-flights-edit';

	constructor($page) {
		super($page);

		this.$title = $page.$('.js-title');
		this.$rocketName = $page.$('.js-rocket-name');

		this.$form = $page.$('form');
		this.$form.enableSubmitButtonTracking();

		this.$txtDate = this.$form.$('[name="date"]');
		this.$txtTime = this.$form.$('[name="time"]');
		
		this.$txtLocation = this.$form.$('[name="location"]');
		
		this.$ddlCert = this.$form.$('[name="cert"]');

		var me = this;
		this.locationSelect = new TomSelect(this.$txtLocation[0], {
			create: function (input) {
				return { id: input, name: input, newlyCreated: true }
			},
			// https://github.com/orchidjs/tom-select/issues/145
			//persist: false,
			plugins: {
				remove_button: {
					title: 'Remove this item',
				},
				//dropdown_input: {},
				//restore_on_backspace: {}
			},
			// the placeholder will be hidden when the control has one or more items (selected options) and is not focused.
			hidePlaceholder: true,
			labelField: 'name',
			valueField: 'id',
			maxItems: 1,
			onItemAdd: function(value) {
				var location = this.options[value];
				me.selectedLocation = location;
				if(!location.newlyCreated)
				{
					me.$txtLatitude.value = location.latitude;
					me.$txtLongitude.value = location.longitude;
				}
			},
			onItemRemove: function() {
				me.selectedLocation = null;
			},
			onClear: function(){
				me.selectedLocation = null;
			}
		});
		locationRepository.list(locations => {
			//this.locationSelect.clear();
			this.locationSelect.clearOptions();
			this.locationSelect.addOption(locations)
		})

		this.$txtLatitude = this.$form.$('[name=latitude]')
		this.$txtLongitude = this.$form.$('[name=longitude]')

		this.$txtMotor = this.$form.$('[name="motor"]');
		this.$txtDelay = this.$form.$('[name="delay"]');
		this.$txtWeight = this.$form.$('[name="weight"]');
		this.$txtStability = this.$form.$('[name="stability"]');
		
		this.$motors = this.$form.$('[name="motor"]');
		let motorOptions = motors.map(m => ({ 
			value: m.name + (m.type ? ' ' + m.type : '') + (m.size ? ' ' + m.size : '') + ' (' + m.manufacturer + ')',
			text: m.name + (m.type ? ' ' + m.type : '') + (m.size ? ' ' + m.size : '') + ' (' + m.manufacturer + ')',
			motor: m
		}));

		var config = {
			options: motorOptions,
			persist: false,
			plugins: {
				remove_button: {
					title: 'Remove this item',
				},
				//dropdown_input: {},
				//restore_on_backspace: {}
			},
			maxItems: 1,
			maxOptions: 9999,
			onItemAdd: value => {
				let motor = motorOptions.filter(x => x.value == value)[0].motor;
				if(this.rocket.weight !== null)
				{
					this.$txtWeight.value = this.rocket.weight + (motor.weight || 0);
				}
				
				//this.setTextboxValue('');
				//this.refreshOptions();
				this.$form.$('[name="delay"]').focus()
			}
		};
		this.motorsSelect = new TomSelect(this.$motors[0], config);

		this.$btnSave = $page.$('.js-btn-save');
		this.$btnSave.addClickListener(async e => {
			var me = this;
			if (!this.$form.validate())
				return;

			this.$form.setSubmitting(true);
				
			if(this.selectedLocation?.newlyCreated)
			{
				await locationRepository.add({
					id: locationRepository.newUid(),
					name: this.selectedLocation.name,
					latitude: parseFloat(this.$txtLatitude.value),
					longitude: parseFloat(this.$txtLongitude.value)
				});
			}
			
			let occurredOn = new Date();
			occurredOn.setTime(me.$txtDate.value + me.$txtTime.value);

			var flight = {
				id: me.flight?.id ?? rocketRepository.newUid(),
				occurredOn: occurredOn,
				createdOn: me.flight?.createdOn ?? new Date(),
				location: me.selectedLocation?.name || null,
				locationId: me.selectedLocation?.id || null,
				latitude: me.$txtLatitude.value,
				longitude: me.$txtLongitude.value,
				motor: me.$txtMotor.value,
				delay: me.$txtDelay.value,
				weight: me.$txtWeight.value,
				stability: me.$txtStability.value,
				certification: me.$ddlCert.value
			}
			
			rocketRepository.saveFlight(me.rocket.id, flight).then(r => {
				return pageManager.replace(pageManager.getPath('rockets-view', { rocketId: me.rocket.id }));
			}).finally(() => {
				me.$form.setSubmitting(false);
			});
		});

		$page.$('.js-geolocate').addClickListener(e => {
			this.geolocate();
		})
	}

	geolocate() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(async position => {
				this.$txtLatitude.value = position.coords.latitude;
				this.$txtLongitude.value = position.coords.longitude;
				
				let location = await locationRepository.find(position.coords.latitude, position.coords.longitude);
				if (location) {
					this.locationSelect.clear();
					this.locationSelect.addItem(location.id)
				}

			});
		} else {
			//fail
		}
	}

	boot(opts) {
		let callback = (rocket, flight) => {
			this.rocket = rocket;

			if (!this.rocket) {
				this.unsub();
				// TODO: mark as dirty/dead
				return;
			}

			this.$rocketName.textContent = this.rocket.name;
			this.$rocketName.href = pageManager.getPath('rockets-view', { rocketId: this.rocket.id });

			if (flight) {
				this.flight = flight;

				this.$btnSave.textContent = 'Save flight';
				this.title = 'Edit flight'
				this.$title.textContent = this.title;
			} else {
				this.$btnSave.textContent = 'Add flight';
				this.title = 'Add flight'
				this.$title.textContent = this.title;
			}
		}

		let result = null;
		if(opts.flightId)
			result = rocketRepository.getRocketAndFlight(opts.rocketId, opts.flightId, callback);
		else 
			result = rocketRepository.getRocket(opts.rocketId, callback);
		
		return result.then(unsub => this.unsub = unsub);
	}

	show(opts) 
	{
		// reset from any unsaved edits
		if (this.flight) {
			this.$txtDate.value = this.flight.occurredOn;
			this.$txtTime.value = this.flight.occurredOn;
			
			if(this.flight.locationId)
			{
				this.locationSelect.clear();
				this.locationSelect.addItem(this.flight.locationId);
			}
			
			this.$txtLatitude.value = this.flight.latitude;
			this.$txtLongitude.value = this.flight.longitude;

			this.$txtMotor.value = this.flight.motor || '';
			this.motorsSelect.setValue(this.flight.motor || '');
			this.$txtDelay.value = this.flight.delay || '';
			this.$txtWeight.value = this.flight.weight || '';
			this.$txtStability.value = this.flight.stability || '';
			this.$ddlCert.value = this.flight.certification || ''
		} else {
			let now = new Date();
			this.$txtDate.value = now;
			this.$txtTime.value = now;

			this.geolocate();
		}
		
		return super.show(opts);
	}

	hide(opts)
	{
		this.$form.reset();
		this.motorsSelect.clear();

		return super.hide(opts);
	}
})
