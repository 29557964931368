import { onSnapshot } from '@trullock/firebase'


let locations = null;
let defaultLocations = [
	{
		id: '_mrc',
		name: 'Midland Rocketry Club',
		latitude: 52.66895306197168,
		longitude: -1.5242515071575777
	},
	{
		id: '_ears',
		name: 'East Anglian Rocketry Society',
		latitude: 52.250929245851864,
		longitude: -0.09260065820723146
	}
];

/**
 * Generates a new Uid
 * @returns {string} a new Uid
 */
export function newUid() {
	return firebase.firestore().collection("placeholder").doc().id
}


function init() {
	return list(locs => {
		locations = locs.concat(defaultLocations);
	});
}

export function list(callback) {
	return onSnapshot(() =>
		firebase.firestore()
			.collection('users')
			.doc(firebase.auth().currentUser.uid)
			.collection('locations'),
		snap => {
			let locs = snap.docs.map(d => d.data());
			locations = locs.concat(defaultLocations);
			callback(locations);
		});
}

export async function find(lat, long)
{
	if(!locations)
		await init();

	if(locations.length == 0)
		return null;

	let candidates = locations.map(l => {

		// No need to care about Haversine, simple pythag will do at the distances we care about.
		let dx = Math.abs(lat - l.latitude);
		let dy = Math.abs(long - l.longitude);
		let d = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));

		return {
			l: l,
			d: d
		}
	});
	// get all locations within 1km
	candidates = candidates.filter(x => x.d * 111139 < 1000)
							.sort((a, b) => a.d - b.d)
							
	return candidates.length ? candidates[0].l : null;
}

export function add(location)
{
	return firebase.firestore()
			.collection('users')
			.doc(firebase.auth().currentUser.uid)
			.collection('locations')
			.doc(location.id)
			.set(location);
}