import Page from '../js/page.js';
import {registerPage} from '@trullock/page-manager';

export default registerPage({'loading': '/loading'}, class extends Page {

	static existingDomSelector = '#page-loading';

	constructor($page) {
		super($page);
	}
});