import Page from '../js/page.js';
import * as pageManager from '@trullock/page-manager';
import * as rocketRepository from '../js/rocket-repository.js'
import {motors} from '../js/motors.js'
import TomSelect from 'tom-select'

export default pageManager.registerPage({'rockets-flights-outcome': '/rockets/{rocketId}/flights/{flightId}/outcome'}, class extends Page {
	static requireAuth = true;

	constructor($page) {
		super($page);

		this.$rocketName = $page.$('.js-rocket-name');

		this.$form = $page.$('form');
		this.$form.enableSubmitButtonTracking();

		this.$txtLatitude = this.$form.$('[name=latitude]')
		this.$txtLongitude = this.$form.$('[name=longitude]')
		this.$txtNotes = this.$form.$('[name="notes"]');

		$page.$('.js-btn-create').addClickListener(e => {
			if (!this.$form.validate())
				return;

			this.$form.setSubmitting(true);
				
			var outcome = {
				outcome: this.$page.$('[name=outcome]:checked').value,
				landingLatitude: this.$txtLatitude.value,
				landingLongitude: this.$txtLongitude.value,
				notes: this.$txtNotes.value,
				certificationOutcome: this.flight.certification ? this.$page.$('[name=certificationOutcome]:checked').value : null
			}
			
			rocketRepository.setFlightOutcome(this.rocket.id, this.flight.id, outcome).then(r => {
				return pageManager.navigate(pageManager.getPath('rockets-view', { rocketId: this.rocket.id }));
			}).finally(() => {
				this.$form.setSubmitting(false);
			});
		});

		$page.$('.js-geolocate').addClickListener(e => {
			this.geolocate();
		});
	}

	boot(opts) {
		return rocketRepository
			.getRocketAndFlight(opts.rocketId, opts.flightId, (rocket, flight) => {
				this.rocket = rocket;
				this.flight = flight;

				this.$rocketName.textContent = this.rocket.name;
				this.$rocketName.href = pageManager.getPath('rockets-view', { rocketId: this.rocket.id });
				
				if(!flight.certification)
				{
					this.$page.$('.js-cert').classList.add('d-none');
					this.$page.$('[name=certificationOutcome]:checked').checked = false;
				}
			})
			.then(unsub => this.unsub = unsub);
	}

	geolocate() {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(async position => {
				this.$txtLatitude.value = position.coords.latitude;
				this.$txtLongitude.value = position.coords.longitude;
			});
		} else {
			//fail
		}
	}

	show(opts) {
		this.$page.$('[name=outcome][value="' + this.flight.outcome + '"]').checked = true;
		this.$txtLatitude.value = this.flight.landingLatitude;
		this.$txtLongitude.value = this.flight.landingLongitude;
		this.$txtNotes.value = this.flight.notes;

		if(this.flight.certification)
			this.$page.$('[name=certificationOutcome][value="' + this.flight.certificationOutcome + '"]').checked = true;
		
		return super.show(opts);
	}

	hide(opts)
	{
		this.$form.reset();
		return super.hide(opts);
	}
})