import { v4 as uuid } from 'uuid'
import Modal from './modal.js';

export function getAuthProvider(id){
	switch(id){
		case 'google.com':
			return new firebase.auth.GoogleAuthProvider()
		case 'facebook.com':
			return new firebase.auth.FacebookAuthProvider()
		case 'github.com':
			return new firebase.auth.GithubAuthProvider()
		default:
			throw new Error('Unexpected auth provider: `' + id + '`')
	}
}

var $toastTemplate = null;
var $toaster = null;
var slices = {};

function removeToast(t) {
	if (t.removeTimeout)
		window.clearTimeout(t.removeTimeout);

	t.$toast.classList.remove('fadeIn');

	t.removeTimeout = setTimeout(e => {
		t.$toast.remove();
	}, 250);

	delete slices[t.sliceId];
}

export function showToast(opts){
	if(!$toastTemplate){
		$toastTemplate = document.$('.js-template-toast');
		$toastTemplate.remove();
		$toaster = document.$('.js-toaster');
	}

	var $toast = $toastTemplate.cloneNode(true);
	var sliceId = new Date().getTime();
	var toast = {
		id: sliceId,
		removeTimeout: null,
		$toast: $toast
	};
	slices[sliceId] = toast;


	$toast.$('.js-message').textContent = opts.message;
	$toast.classList.add(opts.style);


	var $action = $toast.$('.js-action');
	
	if(!opts.buttons || opts.buttons.length == 0)
		$action.remove();
	else {
		var $button = $action.$('button');
		$button.textContent = opts.buttons[0].text;
		$button.addClickListener(e => {
			e.preventDefault();
			removeToast(toast);
			opts.buttons[0].action();
		});
	}

	

	$toaster.appendChild($toast);
	$toast.offsetWidth;
	$toast.classList.add('fadeIn', 'show');

	function startTimer()
	{
		toast.removeTimeout && window.clearTimeout(toast.removeTimeout);
		toast.removeTimeout = setTimeout(e => {
			removeToast(toast);
		}, 4000);
	}

	$toast.addEventListener('mouseenter', e => { 
		toast.removeTimeout && window.clearTimeout(toast.removeTimeout);
	});

	$toast.addEventListener('mouseleave', e => {
		if(e.target == $toast)
			startTimer()
	});

	startTimer();
}

export function clearToast(){
	Object.keys(slices).forEach(s =>{
		removeToast(slices[s]);
	});
}

export function updateFormGroupIds($formGroup){

	var $checks = $formGroup.$('.form-check, .custom-radio, .btn-group-toggle span');
	if($checks.length > 0){
		$checks.forEach($check => updateFormGroupIds($check));
		return;
	}

	var $input = $formGroup.$('textarea,input,select');
	if(!$input)
		return;
		
	var $label = $formGroup.$('label');
	var $validation = $formGroup.$('.feedback');

	$input.id = 'inp' + uuid().replaceAll('-', '_');
	if ($label)
		$label.htmlFor = $input.id;

	if ($validation) {
		$validation.id = $input.id + '_validation';
		$input.setAttribute('aria-describedby', $validation.id);
	}
}

var $confirmDialog = null, confirmDialog = null;
var positiveAction = null, negativeAction = null;
export function showConfirm(options)
{
	if (!$confirmDialog)
	{
		$confirmDialog = document.$('.js-confirm-dialog');
		confirmDialog = new Modal($confirmDialog);
		$confirmDialog.$('.js-positive').addClickListener(e => {
			$confirmDialog.$('button').forEach($b => $b.disabled = true);
			e.target.setSubmitting(true);
			(positiveAction ? positiveAction() : Promise.resolve()).then(() => confirmDialog.hide())
		});
		$confirmDialog.$('.js-negative').addClickListener(e => {
			$confirmDialog.$('button').forEach($b => $b.disabled = true);
			e.target.setSubmitting(true);
			(negativeAction ? negativeAction() : Promise.resolve()).then(() => confirmDialog.hide())
		});
	}
	
	positiveAction = options.positiveAction;
	negativeAction = options.negativeAction;

	$confirmDialog.$('.js-title').textContent = options.title;
	$confirmDialog.$('.js-message').innerHTML = options.message;
	let $btnPositive = $confirmDialog.$('.js-positive');
	$btnPositive.textContent = options.positiveText;

	$btnPositive.className = 'btn js-positive';
	$btnPositive.classList.add('btn-' + (options.positiveStyle || 'primary'))
	$btnPositive.setSubmitting(false);

	let $btnNegative = $confirmDialog.$('.js-negative');
	$btnNegative.className = 'btn js-negative';
	$btnNegative.classList.toggle('d-none', options.negativeText === null);
	$btnNegative.classList.add('btn-' + (options.negativeStyle || 'white'))
	$btnNegative.textContent = options.negativeText || 'Cancel';
	$btnNegative.setSubmitting(false);
	confirmDialog.show();
}


var $inputDialog = null, inputDialog = null;
export function showInput(options) {
	if (!$inputDialog) {
		$inputDialog = document.$('.js-input-dialog');
		inputDialog = new Modal($inputDialog);
		$inputDialog.$('.js-positive').addClickListener(e => {
			if($inputDialog.$('form').validate())
				inputDialog.hide()
		});
		$inputDialog.$('.js-negative').addClickListener(e => inputDialog.hide());
	}

	$inputDialog.$('.js-title').textContent = options.title;
	$inputDialog.$('label').textContent = options.label;
	$inputDialog.$('input').placeholder = options.placeholder || '';
	$inputDialog.$('input').value = options.value || '';
	let $btnPositive = $inputDialog.$('.js-positive');
	$btnPositive.textContent = options.positiveText;

	$btnPositive.classList.remove('btn-primary', 'btn-danger', 'btn-success');
	$btnPositive.classList.add('btn-' + (options.positiveStyle || 'primary'))

	let $btnNegative = $inputDialog.$('.js-negative');
	$btnNegative.textContent = options.negativeText || 'Cancel';
	inputDialog.show()
	$inputDialog.$('input').focus();

	return new Promise((resolve, reject) => {
		$btnPositive.addClickListener(() => {
			if ($inputDialog.$('form').validate())
				resolve($inputDialog.$('input').value);
		});
		$btnNegative.addClickListener(reject);
	});
}
