import Page from '../js/page.js';
import * as Language from '../js/language.js';
import * as pageManager from '@trullock/page-manager';

export default pageManager.registerPage({'account-delete': '/account/delete'}, class extends Page {
	static requireAuth = true;
	constructor($page) {
		super($page);
		
		this.$form = this.$page.$('form');
		this.$txtPassword = this.$form.$('[name=password]');

		this.$form.enableSubmitButtonTracking();
		this.$form.submitHandler(() => {
			
			const credential = firebase.auth.EmailAuthProvider.credential(
				firebase.auth().currentUser.email,
				this.$txtPassword.value
			);

			firebase.auth().currentUser.reauthenticateWithCredential(credential)
				.then(() => firebase.auth().currentUser.delete())
				.then(result => {
					this.$form.setSubmitting(false);
					return pageManager.navigate(pageManager.getPath('account-deleted'))
				})
				.catch(error => {
					// https://firebase.google.com/docs/reference/js/firebase.User#reauthenticatewithcredential
					this.$form.setValidationResult(Language.validation[error.code] || Language.validation['error']);
				});
		});

		
		this.$btnGoogle = this.$page.$('.js-google');
		this.$btnGoogle.addClickListener(e => {
			this.deleteViaSSO(new firebase.auth.GoogleAuthProvider());
		});

		this.$btnGithub = this.$page.$('.js-github');
		this.$btnGithub.addClickListener(e => {
			this.deleteViaSSO(new firebase.auth.GithubAuthProvider());
		});

		this.$btnFacebook = this.$page.$('.js-facebook');
		this.$btnFacebook.addClickListener(e => {
			this.deleteViaSSO(new firebase.auth.FacebookAuthProvider());
		});
	}

	deleteViaSSO(provider){
		firebase.auth().signInWithPopup(provider)
			.then(result => firebase.auth().currentUser.delete())
			.then(e => pageManager.navigate(pageManager.getPath('account-deleted')))
			.catch(error => {
				console.error(error);
				e.target.setValidationResult(Language.validation[error.code] || Language.validation['error']);
			});
	}

	show(opts) {

		return firebase.auth().fetchSignInMethodsForEmail(firebase.auth().currentUser.email).then(methods => {
			if(methods.indexOf('password') > -1)
				this.$form.classList.remove('d-none');
			else
				this.$form.classList.add('d-none');
			
			if(methods.indexOf('google.com') > -1)
				this.$btnGoogle.classList.remove('d-none');
			else
				this.$btnGoogle.classList.add('d-none');

			if(methods.indexOf('github.com') > -1)
				this.$btnGithub.classList.remove('d-none');
			else
				this.$btnGithub.classList.add('d-none');

			if(methods.indexOf('facebook.com') > -1)
				this.$btnFacebook.classList.remove('d-none');
			else
				this.$btnFacebook.classList.add('d-none');

		}).then(() => super.show(opts));
	}
});