import trullockForms from '@trullock/forms';
import * as Auth from './auth.js';
import * as pageManager from '@trullock/page-manager';
import { validation } from './language.js'
import { clearToast, showToast, updateFormGroupIds, showConfirm } from './utils.js'

import * as pages from './pages.js';

export default function() {
	var $navMenu;

	trullockForms(validation.messages);
	var authReady = Auth.init();
	
	document.$.intercept('input[type=date]').value = {
		// Return selected date
		get: value => {
			let parsed = Date.parse(value);
			let datetime = new Date();
			datetime.setTime(parsed);
			return datetime.getTime();
		},
		set: value => {
			if (!(value instanceof Date))
				return value;
			// Set date to local value
			return value.toISOString().slice(0, 10);
		}
	}

	document.$.intercept('input[type=time]').value = {
		// Return time as number of ms since UTC midnight
		get: value => {
			if(value == "")
				return 0;

			let parts = value.split(':');
			let ms = 0;
			ms += parseInt(parts[0], 10) * 60 * 60 * 1000;
			ms += parseInt(parts[1], 10) * 60 * 1000;
			// account for local timezone offset from UTC
			ms += new Date().getTimezoneOffset() * 60 * 1000;
			return ms;
		},
		// Sets the time to local Hours and Minutes
		set: value => {
			if (!(value instanceof Date))
				return value;

			let time = ('0' + value.getHours()).substr(-2) + ':' + ('0' + value.getMinutes()).substr(-2);
			return time;
		}
	}


	document.addEventListener('click', e => {
		var $btn = e.target.matches('.js-reveal') ? e.target : e.target.closest('.js-reveal');
		if (!$btn)
			return;

		e.preventDefault();

		var $input = $btn.closest('.input-button-right').$('input');
		var $eye = $btn.$('.fe');

		if ($input.type == 'password') {
			$input.type = 'text';
			$eye.classList.remove('fe-eye');
			$eye.classList.add('fe-eye-off');
		} else {
			$input.type = 'password';
			$eye.classList.add('fe-eye');
			$eye.classList.remove('fe-eye-off');
		}

		$input.focus();
	});

	document.addEventListener('submitted', e => e.target.matches('form') && e.target.setSubmitting(true, e.detail))
	document.addEventListener('submission-complete', e => {
		if (!e.target.matches('form'))
			return;

		e.target.setSubmitting(false, e.detail.button);
	});


	document.addEventListener('click', e => {
		if(e.target.matches('a.disabled'))
		{
			e.preventDefault();
			e.stopImmediatePropagation();
			e.stopPropagation()
		}
		
	});

	HTMLDocument.prototype.ready = function() {
		return new Promise(resolve => {
			if (document.readyState !== 'loading') {
				resolve();
			} else {
				document.addEventListener('DOMContentLoaded', function () {
					resolve()
				});
			}
		})
	}

	document.ready().then(() => {

		authReady.then(() => {
			pageManager.init({
				fetchPath: route => `/pages/app/${route.pageClass.htmlName ?? route.routeName}.html`,
				prepareMarkup: $html => {
					$html.$('.form-group').forEach(updateFormGroupIds)
				},
				pageInterrupt: route => {
					if (route.pageClass.requireAuth && !firebase.auth().currentUser)
						return { url: pageManager.getPath('account-sign-in') };
					return null;
				},
				loadingPageName: 'loading',
				defaultPageName: 'root',
				error404PageName: 'error-404',
				beforeUnload: message => new Promise(resolve => {
					showConfirm({
						title: 'Confirm',
						message: message,
						positiveText: 'I\'m sure',
						negativeText: 'Cancel',
						positiveAction: () => { resolve(true); return Promise.resolve() },
						negativeAction: () => { resolve(false); return Promise.resolve() }
					});
				})
			})
		});
 
		$navMenu = document.querySelector('#navbarSupportedContent');
		document.querySelector('.navbar-toggler').addEventListener('click', e => {
			$navMenu.classList.toggle('show');
			$navMenu.$('.dropdown-menu').classList.remove('d-block');
		});
		

		// listen for navigations
		document.addEventListener('click', e => {
			if(e.ctrlKey || e.metaKey)
				return;

			var $a = e.target.matches('a') ? e.target : e.target.closest('a');
			if(!$a)
				return;

			if($a.target || $a.protocol == "mailto:")
				return;

			var href = $a.pathname + $a.search + $a.hash;
			if(href == '')
				return;
				
			pageManager.navigate(href, {}, true)

			e.preventDefault();
		}, false);

		// click blocker for accidental button bashing
		var clickBlocker = null;
		document.addEventListener('click', e => {
			var $target = e.target.matches('button, a') ? e.target : e.target.closest('button, a');
			if(!$target)
				return;
			
			if(clickBlocker)
			{
				e.stopPropagation();
				e.preventDefault();
				return;
			}

			clickBlocker = window.setTimeout(_ => clickBlocker = null, 250);
			
		}, true);

		// user state classes
		firebase.auth().onAuthStateChanged(user => {
			var $anons = document.$('.user-anon');
			var $autheds = document.$('.user-authed');

			if(user){
				$anons.forEach($el => $el.classList.add('d-none'));
				$autheds.forEach($el => $el.classList.remove('d-none'));
			} else {

				// flush all pages on signout
				pageManager.purgeCache();

				$anons.forEach($el => $el.classList.remove('d-none'));
				$autheds.forEach($el => $el.classList.add('d-none'));
			}
		})

		// dropdown menus
		document.addEventListener('click', e => {
			var $button = e.target.matches('.dropdown-toggle') ? e.target : e.target.closest('.dropdown-toggle');
			
			var $menu = $button && $button.parentElement.$('.dropdown-menu');
			document.$('.dropdown-menu').forEach($m => $m != $menu?.[0] && $m.classList.remove('show'));
			
			if ($menu)
			{
				e.preventDefault();
				if($menu.className.indexOf('show') > -1)
				{
					$menu.classList.remove('show');
				} else {
					$menu.classList.add('show');

					var top = $button.offsetTop + $button.offsetHeight;
					$menu.style.top = (top + 5) + 'px';
					var right = $button.offset().left + $button.offsetWidth;
					if(window.innerWidth - right < $menu.offsetWidth + 20)
					{
						$menu.style.right = '0';
						$menu.style.left = 'inherit';
					} else {
						$menu.style.right = 'inherit';
						$menu.style.left = '0';
					}

					var bottom = $menu.offset().top + $menu.offsetHeight;
					if(bottom > window.pageYOffset + window.innerHeight)
						window.scroll(0, bottom);
				}
			}

		}, false);

		var $body = document.body;
		var $navbar = document.$('.navbar');

		document.addEventListener('page-shown', e=> {
			if (e.detail.opts.route.path != '/app/loading') {
				// never a navbar inside an iframe
				$navbar.classList.toggle('d-none', e.detail.page.constructor.showNavbar === false || window.self !== window.top);
				$body.classList.toggle('o-hidden', e.detail.page.constructor.overflowHidden === true);

				// https://developers.google.com/gtagjs/reference/event#page_view
				firebase.analytics().logEvent('page_view', {
					page_location: window.location.protocol + '//' + window.location.host + e.detail.opts.route.path,
					page_path: e.detail.opts.route.path,
					page_title: e.detail.page.title
				});
			}
		});

		document.addEventListener('page-hidden', e => {
			clearToast();
		});
	});
}
