import Page from '../js/page.js';
import {registerPage} from '@trullock/page-manager';
import * as Language from '../js/language.js';
import {showToast, getAuthProvider} from '../js/utils.js'
import { countPasswordBreaches } from '../js/hibp.js'

export default registerPage({'account-security': '/account/security'}, class extends Page {
	static requireAuth = true;

	constructor($page) {
		super($page);

		this.$providers = this.$page.$('.js-providers');
		this.$providerTemplate = this.$providers.$('.js-template');
		this.$providerTemplate.remove();
		this.$noProviders = this.$providers.$('.js-no-providers');

		this.$currentPassword = this.$page.$('.js-current-password');
		this.$txtCurrentPassword = this.$page.$('[name=current-password]');
		this.$txtNewPassword = this.$page.$('[name=new-password]');

		this.$form = this.$page.$('form');
		this.$form.submitHandler(() => {

			let password = this.$txtNewPassword.value;

			countPasswordBreaches(password).then(breaches => {
				if(breaches > 0)
				{
					this.$form.setValidationResult({ 'new-password': Language.validation.messages.breached(breaches)});
					return;
				}


				let reauthed = null;
				if (this.passwordAuth){
					const credential = firebase.auth.EmailAuthProvider.credential(
						firebase.auth().currentUser.email,
						this.$txtCurrentPassword.value
					);
					reauthed = firebase.auth().currentUser.reauthenticateWithCredential(credential);
				} else {
					reauthed = firebase.auth().currentUser.reauthenticateWithPopup(getAuthProvider(this.nonPasswordAuth.providerId))
				}
				
				return reauthed.then(() => {
					firebase.auth().currentUser.updatePassword(password).then(e => {
						
						this.render();

						showToast({
							style: 'success',
							message: 'Your password has been updated.'
						})
					});
				}).catch(error => {
					// https://firebase.google.com/docs/reference/js/firebase.User#reauthenticatewithcredential
					console.error(error);
					// TODO: this doesnt render anything
					this.$form.setValidationResult(Language.validation[error.code] || Language.validation['error']);
				});
			}).then(() => this.$form.setSubmitting(false));
		});
	}

	render() {

		this.$form.reset();

		this.$providers.$('.js-template').forEach($t => $t.remove());

		var x = firebase.auth().currentUser;

		this.passwordAuth = x.providerData.filter(p => p.providerId == "password");
		this.passwordAuth = this.passwordAuth.length == 1 ? this.passwordAuth[0] : null;
		
		this.nonPasswordAuth = x.providerData.filter(p => p != this.passwordAuth)
		this.nonPasswordAuth = this.nonPasswordAuth.length == 1 ? this.nonPasswordAuth[0] : null;
		
		this.$noProviders.classList.remove('d-none');
		
		if (!this.passwordAuth)
		{
			this.$currentPassword.classList.add('d-none');
			this.$txtCurrentPassword.disabled = true;
		}
		else
		{
			this.$currentPassword.classList.remove('d-none');
			this.$txtCurrentPassword.disabled = false;
		}

		let externalProviders = x.providerData.filter(x => x != this.passwordAuth);
		externalProviders.forEach(profile => {

			this.$noProviders.classList.add('d-none');

			var $p = this.$providerTemplate.cloneNode(true);
			$p.$('.js-name').textContent = Language.authProviders[profile.providerId];
			$p.$('.js-email').textContent = profile.email;
			$p.$('.js-avatar').src = profile.photoURL;
			
			if (!this.passwordAuth && externalProviders.length == 1) {
				$p.$('.js-unlink').disabled = true;
			}
			else {
				$p.$('.js-unlink').addClickListener(e => {
					e.target.setSubmitting(true);
					x.unlink(profile.providerId).then(() => {
						this.render();
						showToast({
							style: 'success',
							message: Language.authProviders[profile.providerId] + ' authentication removed.'
						})
					});
				})

			}

			this.$providers.appendChild($p);
		});
	}

	show(opts) {
		this.render();
		return super.show(opts);
	}
})