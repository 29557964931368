import Page from '../js/page.js';
import * as Language from '../js/language.js';
import * as pageManager from '@trullock/page-manager';
import {showToast } from '../js/utils.js'

export default pageManager.registerPage({'account-sign-in': '/account/sign-in'}, class extends Page {

	constructor($page) {
		super($page);
		
		this.$form = this.$page.$('form');
		this.$txtEmail = this.$form.$('[name=email]');
		this.$txtPassword = this.$form.$('[name=password]');

		this.$form.submitHandler(() => {
			this.resetAuthErrors();

			let email = this.$txtEmail.value;
			let password = this.$txtPassword.value;

			firebase.auth().signInWithEmailAndPassword(email, password)
				.then(result => {
					pageManager.navigate('goal');

					this.analytics(result);
					
					if(this.pendingCred)
					{
						result.user.linkWithCredential(this.pendingCred).then(result => {
							showToast({
								style: 'success',
								message: this.pendingCredLinkedMessage
							})
						}, e => console.error(e));
					}
				})
				.catch(error => {
					if(error.code == 'auth/wrong-password')
					{
						return firebase.auth().fetchSignInMethodsForEmail(email).then(methods => {
							if(methods.indexOf('password') == -1)
							{
								this.glowAuthProviderMethods(methods);
								this.$form.setValidationResult(Language.validation['auth/no-password']);
							}
							else
							{
								this.$form.setValidationResult(Language.validation[error.code] || Language.validation['error']);
							}
						});
					}
					
					this.$form.setValidationResult(Language.validation[error.code] || Language.validation['error']);
				})
				.then(_ => this.$form.setSubmitting(false));
		});

		this.$page.$('.js-reset-password').addClickListener(e => {
			e.stopPropagation();
			pageManager.navigate(e.target.getAttribute('href'), { email: this.$txtEmail.value })
		});

		this.$btnGoogle = this.$page.$('.js-google');
		this.$btnGoogle.addClickListener(_ => {
			var provider = new firebase.auth.GoogleAuthProvider();
			this.signInWithSSO(provider, 'Account successfully linked to your Google account');
		});


		this.$btnGithub = this.$page.$('.js-github');
		this.$btnGithub.addClickListener(_ => {
			var provider = new firebase.auth.GithubAuthProvider();
			this.signInWithSSO(provider, 'Account successfully linked to your Github account');
		});

		this.$btnFacebook = this.$page.$('.js-facebook');
		this.$btnFacebook.addClickListener(_ => {
			var provider = new firebase.auth.FacebookAuthProvider();
			this.signInWithSSO(provider, 'Account successfully linked to your Facebook account');
		});
		
	}

	signInWithSSO(provider, linkedMessage){
		this.resetAuthErrors();
		this.$form.reset(); // to prevent chrome suggesting to save unused details in the form

		firebase.auth().signInWithPopup(provider).then(result => {
			pageManager.navigate('goal');

			this.analytics(result);

			if(this.pendingCred)
			{
				result.user.linkWithCredential(this.pendingCred).then(result => {
					showToast({
						style: 'success',
						message: this.pendingCredLinkedMessage
					})
				}, e => console.error(e));
			}
		}).catch(error => {

			if(error.code == 'auth/account-exists-with-different-credential')
			{
				this.handleExistingAuth(error, linkedMessage);
				return;
			}

			console.error(error);
			this.$form.setValidationResult(Language.validation[error.code] || Language.validation['error']);
		});

	}

	analytics(userCredential){
		firebase.analytics().logEvent(userCredential.additionalUserInfo?.isNewUser ? 'sign-up' : 'login', {
			method: userCredential.credential.providerId
		});
	}

	resetAuthErrors() {
		this.$btnGoogle.classList.remove('glow');
		this.$btnGithub.classList.remove('glow');
		this.$btnFacebook.classList.remove('glow');
		this.$txtPassword.classList.remove('glow');
	}

	handleExistingAuth(error, linkedMessage){
		this.pendingCred = error.credential;
		this.pendingCredLinkedMessage = linkedMessage
		this.$txtEmail.value = error.email;

		firebase.auth().fetchSignInMethodsForEmail(error.email).then(methods => {
			this.glowAuthProviderMethods(methods);
		});

		this.$form.setValidationResult(Language.validation[error.code]);
	}

	glowAuthProviderMethods(methods) {
		if(methods.indexOf('google.com') > -1)
			this.$btnGoogle.classList.add('glow');
		if(methods.indexOf('github.com') > -1)
			this.$btnGithub.classList.add('glow');
		if(methods.indexOf('facebook.com') > -1)
			this.$btnFacebook.classList.add('glow');
		if(methods.indexOf('password') > -1)
			this.$txtPassword.classList.add('glow');
	}

	show(opts) {
		this.resetAuthErrors();
		this.pendingCred = null;
		this.pendingCredLinkedMessage = null;
		this.$form.reset();

		return super.show(opts);
	}

})