import Page from '../js/page.js';
import * as Language from '../js/language.js';
import * as pageManager from '@trullock/page-manager';

export default pageManager.registerPage({'account-reset-password': '/account/reset-password'}, class extends Page {
	constructor($page) {
		super($page);

		let $form = this.$page.$('form');
		this.$txtEmail = $form.$('[name=email]');

		$form.submitHandler(() => {
			
			let email = $form.querySelector('[name=email]').value;

			firebase.auth().sendPasswordResetEmail(email, { url: window.location.protocol + '//' + window.location.host + pageManager.getPath('root') })
				.then(_ => this.handleResetPassword())
				.catch(error => $form.setValidationResult(Language.validation[error.code] || Language.validation['error']))
				.then(_ =>	$form.setSubmitting(false));
		});
	}

	handleResetPassword(){
		return pageManager.show(pageManager.getPath('account-reset-password-sent'));
	}

	show(opts){
		if(opts.email)
			this.$txtEmail.value = opts.email;
		return super.show(opts);
	}
})