export function init() {

	return new Promise(resolve => {
		let booted = false;
		firebase.auth().onAuthStateChanged((user) => {
			if(!booted){
				booted = true;
				resolve();
			}
		});
	});
}