export const motors = [
	{
		"manufacturer": "Estes",
		"name": "1/4A3"
	},
	{
		"manufacturer": "Estes",
		"name": "1/2A3"
	},
	{
		"manufacturer": "Estes",
		"name": "1/2A6"
	},
	{
		"manufacturer": "Estes",
		"name": "A10"
	},
	{
		"manufacturer": "Estes",
		"name": "A3"
	},
	{
		"manufacturer": "Estes",
		"name": "A8"
	},
	{
		"manufacturer": "Estes",
		"name": "B4"
	},
	{
		"manufacturer": "Estes",
		"name": "B6"
	},
	{
		"manufacturer": "Estes",
		"name": "C5"
	},
	{
		"manufacturer": "Estes",
		"name": "C11"
	},
	{
		"manufacturer": "Estes",
		"name": "C6"
	},
	{
		"manufacturer": "Estes",
		"name": "D12"
	},
	{
		"manufacturer": "Estes",
		"name": "E12"
	},
	{
		"manufacturer": "Estes",
		"name": "E9"
	},
	{
		"manufacturer": "Estes",
		"name": "E16"
	},
	{
		"manufacturer": "Estes",
		"name": "F15"
	},
	{
		"manufacturer": "Estes",
		"name": "F26"
	},
	{
		"manufacturer": "Estes",
		"name": "F50"
	},
	{
		"manufacturer": "Estes",
		"name": "G40"
	},
	{
		"manufacturer": "AeroTech",
		"name": "C3",
		"type": "Blue Thunder",
		"size": "RMS-R/C-18/70",
		"weight": 23.9
	},
	{
		"manufacturer": "AeroTech",
		"name": "D2",
		"type": "Blue Thunder",
		"size": "RMS-R/C-18/70",
		"weight": 29.3
	},
	{
		"manufacturer": "AeroTech",
		"name": "D24",
		"type": "Blue Thunder",
		"size": "RMS-R/C-18/70",
		"weight": 32
	},
	{
		"manufacturer": "AeroTech",
		"name": "D7",
		"type": "Blue Thunder",
		"size": "RMS-R/C-24/20-40",
		"weight": 42.2
	},
	{
		"manufacturer": "AeroTech",
		"name": "D10",
		"type": "White Lightning",
		"weight": 25.9
	},
	{
		"manufacturer": "AeroTech",
		"name": "D9",
		"type": "White Lightning",
		"size": "RMS-24/40",
		"weight": 47.4
	},
	{
		"manufacturer": "AeroTech",
		"name": "D15",
		"type": "Blue Thunder",
		"size": "RMS-24/40",
		"weight": 46.9
	},
	{
		"manufacturer": "AeroTech",
		"name": "D13",
		"type": "White Lightning",
		"size": "RMS-R/C-18/70",
		"weight": 31.9
	},
	{
		"manufacturer": "AeroTech",
		"name": "D22",
		"size": "White Lightning",
		"weight": 37.5
	},
	{
		"manufacturer": "AeroTech",
		"name": "D21",
		"type": "Blue Thunder",
		"weight": 25
	},
	{
		"manufacturer": "AeroTech",
		"name": "E26",
		"type": "White Lightning",
		"weight": 44
	},
	{
		"manufacturer": "AeroTech",
		"name": "E7",
		"type": "Blue Thunder",
		"size": "RMS-R/C-24/20-40",
		"weight": 48.4
	},
	{
		"manufacturer": "AeroTech",
		"name": "E11",
		"type": "Blackjack",
		"size": "RMS-24/40",
		"weight": 62.4
	},
	{
		"manufacturer": "AeroTech",
		"name": "E30",
		"type": "Blue Thunder",
		"weight": 47
	},
	{
		"manufacturer": "AeroTech",
		"name": "E12",
		"type": "Blackjack",
		"size": "RMS-R/C-24/20-40",
		"weight": 59.4
	},
	{
		"manufacturer": "AeroTech",
		"name": "E15",
		"type": "White Lightning",
		"weight": 49
	},
	{
		"manufacturer": "AeroTech",
		"name": "E20",
		"type": "White Lightning",
		"weight": 49
	},
	{
		"manufacturer": "AeroTech",
		"name": "E23",
		"type": "Blue Thunder",
		"size": "RMS-29/40-120",
		"weight": 104
	},
	{
		"manufacturer": "AeroTech",
		"name": "E18",
		"type": "White Lightning",
		"size": "RMS-24/40",
		"weight": 59
	},
	{
		"manufacturer": "AeroTech",
		"name": "E6",
		"type": "Blue Thunder",
		"size": "RMS-R/C-24/20-40",
		"weight": 50
	},
	{
		"manufacturer": "AeroTech",
		"name": "E16",
		"type": "White Lightning",
		"size": "RMS-29/40-120",
		"weight": 109
	},
	{
		"manufacturer": "AeroTech",
		"name": "E28",
		"type": "Blue Thunder",
		"size": "RMS-24/40",
		"weight": 55.3
	},
	{
		"manufacturer": "AeroTech",
		"name": "F23",
		"type": "Black Max",
		"weight": 82.3
	},
	{
		"manufacturer": "AeroTech",
		"name": "F44",
		"type": "White Lightning",
		"weight": 48
	},
	{
		"manufacturer": "AeroTech",
		"name": "F12",
		"type": "Blackjack",
		"size": "RMS-24/40",
		"weight": 68.6
	},
	{
		"manufacturer": "AeroTech",
		"name": "F30",
		"type": "Black Max",
		"weight": 70.6
	},
	{
		"manufacturer": "AeroTech",
		"name": "F24",
		"type": "White Lightning",
		"size": "RMS-24/40",
		"weight": 62.9
	},
	{
		"manufacturer": "AeroTech",
		"name": "F62",
		"type": "Black Max",
		"size": "RMS-24/60",
		"weight": 88.1
	},
	{
		"manufacturer": "AeroTech",
		"name": "F63",
		"type": "Redline",
		"size": "RMS-24/60",
		"weight": 82.2
	},
	{
		"manufacturer": "AeroTech",
		"name": "F27",
		"type": "Redline",
		"weight": 80
	},
	{
		"manufacturer": "AeroTech",
		"name": "F39",
		"type": "Blue Thunder",
		"size": "RMS-24/40",
		"weight": 60.6
	},
	{
		"manufacturer": "AeroTech",
		"name": "F37",
		"type": "White Lightning",
		"size": "RMS-29/60",
		"weight": 109.3
	},
	{
		"manufacturer": "AeroTech",
		"name": "F62",
		"type": "Blue Thunder",
		"size": "RMS-29/60",
		"weight": 109
	},
	{
		"manufacturer": "AeroTech",
		"name": "F20",
		"type": "White Lightning",
		"weight": 80.2
	},
	{
		"manufacturer": "AeroTech",
		"name": "F42",
		"type": "Blue Thunder",
		"weight": 75.9
	},
	{
		"manufacturer": "AeroTech",
		"name": "F51",
		"type": "New Blue Thunder",
		"size": "RMS-24/60",
		"weight": 81
	},
	{
		"manufacturer": "AeroTech",
		"name": "F32",
		"type": "Blue Thunder",
		"weight": 64
	},
	{
		"manufacturer": "AeroTech",
		"name": "F35",
		"type": "White Lightning",
		"size": "RMS-24/60",
		"weight": 85
	},
	{
		"manufacturer": "AeroTech",
		"name": "F67",
		"weight": 80.8
	},
	{
		"manufacturer": "AeroTech",
		"name": "F13",
		"type": "Blue Thunder",
		"size": "RMS-R/C-32/60-100",
		"weight": 110.5
	},
	{
		"manufacturer": "AeroTech",
		"name": "F26",
		"type": "Black Max",
		"weight": 101
	},
	{
		"manufacturer": "AeroTech",
		"name": "F22",
		"type": "Blackjack",
		"size": "RMS-29/40-120",
		"weight": 135.6
	},
	{
		"manufacturer": "AeroTech",
		"name": "F52",
		"type": "Classic",
		"weight": 81.4
	},
	{
		"manufacturer": "AeroTech",
		"name": "F23",
		"type": "White Lightning",
		"size": "RMS-R/C-32/60-100",
		"weight": 128.7
	},
	{
		"manufacturer": "AeroTech",
		"name": "F52",
		"type": "Blue Thunder",
		"size": "RMS-29/40-120",
		"weight": 121.6
	},
	{
		"manufacturer": "AeroTech",
		"name": "F16",
		"type": "Blackjack",
		"size": "RMS-R/C-32/60-100",
		"weight": 140.4
	},
	{
		"manufacturer": "AeroTech",
		"name": "F50",
		"type": "Blue Thunder",
		"weight": 84.9
	},
	{
		"manufacturer": "AeroTech",
		"name": "F25",
		"type": "White Lightning",
		"weight": 90
	},
	{
		"manufacturer": "AeroTech",
		"name": "F40",
		"type": "White Lightning",
		"size": "RMS-29/40-120",
		"weight": 126.2
	},
	{
		"manufacturer": "AeroTech",
		"name": "G54",
		"type": "White Lightning",
		"size": "RMS-29/100",
		"weight": 136.5
	},
	{
		"manufacturer": "AeroTech",
		"name": "G104",
		"type": "Blue Thunder",
		"size": "RMS-29/100",
		"weight": 136
	},
	{
		"manufacturer": "AeroTech",
		"name": "G74",
		"type": "White Lightning",
		"weight": 87
	},
	{
		"manufacturer": "AeroTech",
		"name": "G12",
		"type": "Blue Thunder",
		"size": "RMS-R/C-32/60-100",
		"weight": 131
	},
	{
		"manufacturer": "AeroTech",
		"name": "G38",
		"type": "Black Max",
		"weight": 126
	},
	{
		"manufacturer": "AeroTech",
		"name": "G53",
		"type": "Black Max",
		"size": "RMS-29/40-120",
		"weight": 146
	},
	{
		"manufacturer": "AeroTech",
		"name": "G77",
		"type": "Redline",
		"weight": 122.5
	},
	{
		"manufacturer": "AeroTech",
		"name": "G77",
		"type": "Redline",
		"size": "RMS-29/120",
		"weight": 165
	},
	{
		"manufacturer": "AeroTech",
		"name": "G72",
		"type": "Dark Matter",
		"weight": 131.6
	},
	{
		"manufacturer": "AeroTech",
		"name": "G79",
		"type": "White Lightning",
		"weight": 124
	},
	{
		"manufacturer": "AeroTech",
		"name": "G79",
		"type": "White Lightning",
		"size": "RMS-29/120",
		"weight": 157
	},
	{
		"manufacturer": "AeroTech",
		"name": "G339",
		"type": "Warp 9",
		"size": "RMS-38/120",
		"weight": 181
	},
	{
		"manufacturer": "AeroTech",
		"name": "G78",
		"type": "Mojave Green",
		"weight": 125
	},
	{
		"manufacturer": "AeroTech",
		"name": "G67",
		"type": "Redline",
		"size": "RMS-38/120",
		"weight": 201
	},
	{
		"manufacturer": "AeroTech",
		"name": "G61",
		"type": "White Lightning",
		"size": "RMS-38/120",
		"weight": 194
	},
	{
		"manufacturer": "AeroTech",
		"name": "G40",
		"type": "White Lightning",
		"weight": 123
	},
	{
		"manufacturer": "AeroTech",
		"name": "G75",
		"type": "Metalstorm",
		"weight": 133
	},
	{
		"manufacturer": "AeroTech",
		"name": "G25",
		"type": "White Lightning",
		"size": "RMS-29/120",
		"weight": 121
	},
	{
		"manufacturer": "AeroTech",
		"name": "G76",
		"type": "Mojave Green",
		"size": "RMS-29/40-120",
		"weight": 147
	},
	{
		"manufacturer": "AeroTech",
		"name": "G64",
		"type": "White Lightning",
		"size": "RMS-29/40-120",
		"weight": 151
	},
	{
		"manufacturer": "AeroTech",
		"name": "G125",
		"type": "Blue Thunder",
		"weight": 125
	},
	{
		"manufacturer": "AeroTech",
		"name": "G8",
		"type": "Super Thunder",
		"weight": 157.1
	},
	{
		"manufacturer": "AeroTech",
		"name": "G75",
		"type": "Blackjack",
		"size": "RMS-29/180",
		"weight": 232.96
	},
	{
		"manufacturer": "AeroTech",
		"name": "G80",
		"type": "Blue Thunder",
		"weight": 127.90000000000002
	},
	{
		"manufacturer": "AeroTech",
		"name": "G69",
		"type": "Warp 9",
		"size": "RMS-38/120",
		"weight": 201
	},
	{
		"manufacturer": "AeroTech",
		"name": "G11",
		"type": "Super Thunder",
		"weight": 153.7
	},
	{
		"manufacturer": "AeroTech",
		"name": "G12",
		"type": "Super Thunder",
		"weight": 150.29999999999999
	},
	{
		"manufacturer": "AeroTech",
		"name": "G138",
		"type": "Blue Thunder",
		"size": "RMS-29/40-120",
		"weight": 148
	},
	{
		"manufacturer": "AeroTech",
		"name": "H165",
		"type": "Redline",
		"size": "RMS-29/180",
		"weight": 201.6
	},
	{
		"manufacturer": "AeroTech",
		"name": "H238",
		"type": "Blue Thunder",
		"size": "RMS-29/180",
		"weight": 196
	},
	{
		"manufacturer": "AeroTech",
		"name": "H115",
		"type": "Dark Matter",
		"weight": 205
	},
	{
		"manufacturer": "AeroTech",
		"name": "H128",
		"type": "White Lightning",
		"size": "RMS-29/180",
		"weight": 206
	},
	{
		"manufacturer": "AeroTech",
		"name": "H97",
		"type": "Blackjack",
		"size": "RMS-29/240",
		"weight": 277.76
	},
	{
		"manufacturer": "AeroTech",
		"name": "H73",
		"type": "Blackjack",
		"size": "RMS-38/240",
		"weight": 309.12
	},
	{
		"manufacturer": "AeroTech",
		"name": "H283",
		"type": "Super Thunder",
		"size": "DMS"
	},
	{
		"manufacturer": "AeroTech",
		"name": "H148",
		"type": "Redline",
		"size": "RMS-38/240",
		"weight": 309.12
	},
	{
		"manufacturer": "AeroTech",
		"name": "H130",
		"type": "White Lightning",
		"size": "RMS-38/240",
		"weight": 237
	},
	{
		"manufacturer": "AeroTech",
		"name": "H13",
		"type": "Super Thunder",
		"weight": 203.4
	},
	{
		"manufacturer": "AeroTech",
		"name": "H210",
		"type": "Redline",
		"size": "RMS-29/240",
		"weight": 246.4
	},
	{
		"manufacturer": "AeroTech",
		"name": "H180",
		"type": "White Lightning",
		"size": "RMS-29/240",
		"weight": 252
	},
	{
		"manufacturer": "AeroTech",
		"name": "H182",
		"type": "Redline",
		"weight": 207
	},
	{
		"manufacturer": "AeroTech",
		"name": "H220",
		"type": "Blue Thunder",
		"size": "RMS-29/240",
		"weight": 250
	},
	{
		"manufacturer": "AeroTech",
		"name": "H669",
		"type": "Warp 9",
		"size": "RMS-38/240",
		"weight": 252
	},
	{
		"manufacturer": "AeroTech",
		"name": "H123",
		"type": "White Lightning",
		"size": "RMS-38/240",
		"weight": 275
	},
	{
		"manufacturer": "AeroTech",
		"name": "H135",
		"type": "White Lightning",
		"weight": 212
	},
	{
		"manufacturer": "AeroTech",
		"name": "H100",
		"type": "White Lightning",
		"size": "DMS",
		"weight": 261
	},
	{
		"manufacturer": "AeroTech",
		"name": "H250",
		"type": "Mojave Green",
		"size": "RMS-29/240",
		"weight": 256
	},
	{
		"manufacturer": "AeroTech",
		"name": "H242",
		"type": "Blue Thunder",
		"size": "RMS-38/240",
		"weight": 264
	},
	{
		"manufacturer": "AeroTech",
		"name": "H169",
		"type": "Super White Lightning",
		"weight": 202.2
	},
	{
		"manufacturer": "AeroTech",
		"name": "H219",
		"type": "Blue Thunder",
		"weight": 261
	},
	{
		"manufacturer": "AeroTech",
		"name": "H195",
		"type": "New Blue Thunder",
		"weight": 197
	},
	{
		"manufacturer": "AeroTech",
		"name": "H112",
		"type": "Blackjack",
		"size": "RMS-38/360",
		"weight": 379.456
	},
	{
		"manufacturer": "AeroTech",
		"name": "H178",
		"type": "Dark Matter",
		"size": "RMS-38/360",
		"weight": 324
	},
	{
		"manufacturer": "AeroTech",
		"name": "H550",
		"type": "Super Thunder",
		"size": "38 DMS",
		"weight": 316
	},
	{
		"manufacturer": "AeroTech",
		"name": "H170",
		"type": "Metalstorm",
		"size": "RMS-38/360",
		"weight": 330
	},
	{
		"manufacturer": "AeroTech",
		"name": "H999",
		"type": "Warp 9",
		"size": "RMS-38/360",
		"weight": 331
	},
	{
		"manufacturer": "AeroTech",
		"name": "H268",
		"type": "Redline",
		"size": "RMS-29/360",
		"weight": 358.4
	},
	{
		"manufacturer": "AeroTech",
		"name": "H550",
		"type": "Super Thunder",
		"size": "RMS-38/360",
		"weight": 323
	},
	{
		"manufacturer": "AeroTech",
		"name": "H45",
		"type": "White Lightning",
		"weight": 365
	},
	{
		"manufacturer": "AeroTech",
		"name": "I200",
		"type": "White Lightning",
		"size": "RMS-29/360",
		"weight": 357.504
	},
	{
		"manufacturer": "AeroTech",
		"name": "I180",
		"type": "White Lightning",
		"size": "RMS-38/360",
		"weight": 385
	},
	{
		"manufacturer": "AeroTech",
		"name": "I161",
		"type": "White Lightning",
		"size": "RMS-38/360",
		"weight": 366
	},
	{
		"manufacturer": "AeroTech",
		"name": "I218",
		"type": "Redline",
		"size": "RMS-38/360",
		"weight": 371.84
	},
	{
		"manufacturer": "AeroTech",
		"name": "I175",
		"type": "Super White Lightning",
		"weight": 348
	},
	{
		"manufacturer": "AeroTech",
		"name": "I140",
		"type": "White Lightning",
		"weight": 356
	},
	{
		"manufacturer": "AeroTech",
		"name": "I357",
		"type": "Blue Thunder",
		"size": "RMS-38/360",
		"weight": 343
	},
	{
		"manufacturer": "AeroTech",
		"name": "I205",
		"type": "White Lightning",
		"weight": 315
	},
	{
		"manufacturer": "AeroTech",
		"name": "I245",
		"type": "Mojave Green",
		"size": "RMS-38/360",
		"weight": 365
	},
	{
		"manufacturer": "AeroTech",
		"name": "I117",
		"type": "Black Max",
		"size": "RMS-54/426",
		"weight": 566
	},
	{
		"manufacturer": "AeroTech",
		"name": "I225",
		"type": "Black Max",
		"size": "RMS-38/480",
		"weight": 486
	},
	{
		"manufacturer": "AeroTech",
		"name": "I154",
		"type": "Blackjack",
		"size": "RMS-38/480",
		"weight": 491.904
	},
	{
		"manufacturer": "AeroTech",
		"name": "I49",
		"type": "Warp 9",
		"size": "RMS-38/360",
		"weight": 398
	},
	{
		"manufacturer": "AeroTech",
		"name": "I215",
		"type": "Redline",
		"size": "RMS-54/426",
		"weight": 527
	},
	{
		"manufacturer": "AeroTech",
		"name": "I599",
		"type": "Warp 9",
		"size": "RMS-54/426",
		"weight": 512
	},
	{
		"manufacturer": "AeroTech",
		"name": "I229",
		"type": "Blue Thunder",
		"size": "RMS-54/426",
		"weight": 514
	},
	{
		"manufacturer": "AeroTech",
		"name": "I115",
		"type": "White Lightning",
		"size": "RMS-54/426",
		"weight": 545
	},
	{
		"manufacturer": "AeroTech",
		"name": "I170",
		"type": "Mojave Green",
		"size": "RMS-54/426",
		"weight": 528
	},
	{
		"manufacturer": "AeroTech",
		"name": "I285",
		"type": "Redline",
		"size": "RMS-38/480",
		"weight": 492.8
	},
	{
		"manufacturer": "AeroTech",
		"name": "I1299",
		"type": "Warp 9",
		"size": "RMS-38/480",
		"weight": 422
	},
	{
		"manufacturer": "AeroTech",
		"name": "I195",
		"type": "Blackjack",
		"size": "RMS-38/600",
		"weight": 572
	},
	{
		"manufacturer": "AeroTech",
		"name": "I300",
		"type": "Blue Thunder",
		"size": "RMS-38/480",
		"weight": 441
	},
	{
		"manufacturer": "AeroTech",
		"name": "I211",
		"type": "White Lightning",
		"size": "RMS-38/480",
		"weight": 455
	},
	{
		"manufacturer": "AeroTech",
		"name": "I305",
		"type": "Black Max",
		"size": "RMS-38/600",
		"weight": 581
	},
	{
		"manufacturer": "AeroTech",
		"name": "I59",
		"type": "White Lightning/Warp 9",
		"size": "RMS-38/480",
		"weight": 487
	},
	{
		"manufacturer": "AeroTech",
		"name": "I327",
		"type": "Dark Matter",
		"size": "RMS-38/720",
		"weight": 628
	},
	{
		"manufacturer": "AeroTech",
		"name": "I366",
		"type": "Redline",
		"size": "RMS-38/600",
		"weight": 555.52
	},
	{
		"manufacturer": "AeroTech",
		"name": "I280",
		"type": "Dark Matter",
		"weight": 616
	},
	{
		"manufacturer": "AeroTech",
		"name": "I435",
		"type": "Blue Thunder",
		"size": "RMS-38/600",
		"weight": 518
	},
	{
		"manufacturer": "AeroTech",
		"name": "I364",
		"type": "Black Max",
		"size": "RMS-38/720",
		"weight": 678
	},
	{
		"manufacturer": "AeroTech",
		"name": "I600",
		"type": "Redline",
		"size": "RMS-38/720",
		"weight": 602
	},
	{
		"manufacturer": "AeroTech",
		"name": "I284",
		"type": "White Lightning",
		"size": "RMS-38/600",
		"weight": 555.52
	},
	{
		"manufacturer": "AeroTech",
		"name": "I500",
		"type": "Blue Thunder",
		"weight": 576
	},
	{
		"manufacturer": "AeroTech",
		"name": "I350",
		"type": "Redline",
		"weight": 616
	},
	{
		"manufacturer": "AeroTech",
		"name": "I65",
		"type": "White Lightning",
		"weight": 752
	},
	{
		"manufacturer": "AeroTech",
		"name": "J340",
		"type": "Metalstorm",
		"size": "RMS-38/720",
		"weight": 577.3
	},
	{
		"manufacturer": "AeroTech",
		"name": "J420",
		"type": "Redline",
		"size": "RMS-38/720",
		"weight": 649.6
	},
	{
		"manufacturer": "AeroTech",
		"name": "J520",
		"type": "White Lightning",
		"size": "RMS-38/1080",
		"weight": 757.4
	},
	{
		"manufacturer": "AeroTech",
		"name": "J425",
		"type": "Redline",
		"weight": 631
	},
	{
		"manufacturer": "AeroTech",
		"name": "J350",
		"type": "White Lightning",
		"size": "RMS-38/720",
		"weight": 665
	},
	{
		"manufacturer": "AeroTech",
		"name": "J270",
		"type": "White Lightning",
		"weight": 642
	},
	{
		"manufacturer": "AeroTech",
		"name": "J250",
		"type": "White Lightning",
		"size": "DMS",
		"weight": 708
	},
	{
		"manufacturer": "AeroTech",
		"name": "J90",
		"type": "White Lightning",
		"size": "RMS-54/852",
		"weight": 852.544
	},
	{
		"manufacturer": "AeroTech",
		"name": "J500",
		"type": "Mojave Green",
		"size": "RMS-38/720",
		"weight": 654
	},
	{
		"manufacturer": "AeroTech",
		"name": "J250",
		"type": "Black Max",
		"size": "RMS-54/852",
		"weight": 907
	},
	{
		"manufacturer": "AeroTech",
		"name": "J315",
		"type": "Redline",
		"size": "RMS-54/852",
		"weight": 851.2
	},
	{
		"manufacturer": "AeroTech",
		"name": "J180",
		"type": "Blue Thunder",
		"size": "RMS-54/852",
		"weight": 809.088
	},
	{
		"manufacturer": "AeroTech",
		"name": "J275",
		"type": "White Lightning",
		"size": "RMS-54/852",
		"weight": 864.192
	},
	{
		"manufacturer": "AeroTech",
		"name": "J435",
		"type": "Super White Lightning",
		"size": "DMS",
		"weight": 624
	},
	{
		"manufacturer": "AeroTech",
		"name": "J575",
		"type": "Black Max",
		"size": "RMS-38/1080",
		"weight": 914
	},
	{
		"manufacturer": "AeroTech",
		"name": "J460",
		"type": "Blue Thunder",
		"size": "RMS-54/852",
		"weight": 789
	},
	{
		"manufacturer": "AeroTech",
		"name": "J1299",
		"type": "Warp 9",
		"size": "RMS-54/852",
		"weight": 834
	},
	{
		"manufacturer": "AeroTech",
		"name": "J99",
		"type": "Warp 9",
		"size": "RMS-54/852",
		"weight": 899
	},
	{
		"manufacturer": "AeroTech",
		"name": "J570",
		"type": "White Lightning",
		"size": "RMS-38/1080",
		"weight": 902
	},
	{
		"manufacturer": "AeroTech",
		"name": "J825",
		"type": "Redline",
		"size": "RMS-38/1080",
		"weight": 875
	},
	{
		"manufacturer": "AeroTech",
		"name": "J450",
		"type": "Dark Matter",
		"weight": 1223
	},
	{
		"manufacturer": "AeroTech",
		"name": "J135",
		"type": "White Lightning",
		"size": "RMS-54/1280",
		"weight": 1141.06
	},
	{
		"manufacturer": "AeroTech",
		"name": "J401",
		"type": "Black Max",
		"size": "RMS-54/1280",
		"weight": 912
	},
	{
		"manufacturer": "AeroTech",
		"name": "J540",
		"type": "Redline",
		"size": "RMS-54/1280",
		"weight": 1084.16
	},
	{
		"manufacturer": "AeroTech",
		"name": "J510",
		"type": "White Lightning",
		"size": "RMS-38/1320",
		"weight": 1080
	},
	{
		"manufacturer": "AeroTech",
		"name": "J1799",
		"type": "Warp 9",
		"size": "RMS-54/1280",
		"weight": 1111
	},
	{
		"manufacturer": "AeroTech",
		"name": "J800",
		"type": "Blue Thunder",
		"size": "RMS-54/1280",
		"weight": 1085.95
	},
	{
		"manufacturer": "AeroTech",
		"name": "J415",
		"type": "White Lightning",
		"size": "RMS-54/1280",
		"weight": 1168
	},
	{
		"manufacturer": "AeroTech",
		"name": "K456",
		"type": "Dark Matter",
		"size": "RMS-54/1706",
		"weight": 1484
	},
	{
		"manufacturer": "AeroTech",
		"name": "K400",
		"type": "Classic",
		"weight": 1194
	},
	{
		"manufacturer": "AeroTech",
		"name": "K1499",
		"type": "Warp 9",
		"size": "RMS-75/1280",
		"weight": 1741
	},
	{
		"manufacturer": "AeroTech",
		"name": "K76",
		"type": "White Lightning/Warp 9",
		"weight": 1277.9
	},
	{
		"manufacturer": "AeroTech",
		"name": "K2050",
		"type": "Super Thunder",
		"size": "RMS-54/1706",
		"weight": 1292
	},
	{
		"manufacturer": "AeroTech",
		"name": "K185",
		"type": "White Lightning",
		"size": "RMS-54/1706",
		"weight": 1434.05
	},
	{
		"manufacturer": "AeroTech",
		"name": "K535",
		"type": "White Lightning",
		"weight": 1264
	},
	{
		"manufacturer": "AeroTech",
		"name": "K1100",
		"type": "Blue Thunder",
		"size": "RMS-54/1706",
		"weight": 1336
	},
	{
		"manufacturer": "AeroTech",
		"name": "K513",
		"type": "Black Max",
		"size": "RMS-54/1706",
		"weight": 1647
	},
	{
		"manufacturer": "AeroTech",
		"name": "K695",
		"type": "Redline",
		"size": "RMS-54/1706",
		"weight": 1487.36
	},
	{
		"manufacturer": "AeroTech",
		"name": "K550",
		"type": "White Lightning",
		"size": "RMS-54/1706",
		"weight": 1487.36
	},
	{
		"manufacturer": "AeroTech",
		"name": "K540",
		"type": "Metalstorm",
		"size": "RMS-54/1706",
		"weight": 1275
	},
	{
		"manufacturer": "AeroTech",
		"name": "K805",
		"type": "Mojave Green",
		"size": "RMS-54/1706",
		"weight": 1543
	},
	{
		"manufacturer": "AeroTech",
		"name": "K1103",
		"type": "Propellant X",
		"size": "RMS-54/1706",
		"weight": 1459
	},
	{
		"manufacturer": "AeroTech",
		"name": "K990",
		"type": "Dark Matter",
		"size": "RMS-54/2800",
		"weight": 2109
	},
	{
		"manufacturer": "AeroTech",
		"name": "K850",
		"type": "Dark Matter",
		"weight": 2041
	},
	{
		"manufacturer": "AeroTech",
		"name": "K270",
		"type": "White Lightning",
		"size": "RMS-54/2560",
		"weight": 2100
	},
	{
		"manufacturer": "AeroTech",
		"name": "K828",
		"type": "Black Max",
		"size": "RMS-54/2560",
		"weight": 2223
	},
	{
		"manufacturer": "AeroTech",
		"name": "K1275",
		"type": "Redline",
		"size": "RMS-54/2560",
		"weight": 1986
	},
	{
		"manufacturer": "AeroTech",
		"name": "K375",
		"type": "White Lightning/Warp 9",
		"size": "RMS-54/2560",
		"weight": 2106
	},
	{
		"manufacturer": "AeroTech",
		"name": "K700",
		"type": "White Lightning",
		"size": "RMS-54/2560",
		"weight": 2035.26
	},
	{
		"manufacturer": "AeroTech",
		"name": "K480",
		"type": "White Lightning",
		"size": "RMS-54/2560",
		"weight": 2078
	},
	{
		"manufacturer": "AeroTech",
		"name": "K455",
		"type": "White Lightning/Warp 9",
		"weight": 2285.8
	},
	{
		"manufacturer": "AeroTech",
		"name": "K250",
		"type": "White Lightning",
		"weight": 2211.33
	},
	{
		"manufacturer": "AeroTech",
		"name": "K680",
		"type": "Redline",
		"size": "RMS-98/2560",
		"weight": 3035
	},
	{
		"manufacturer": "AeroTech",
		"name": "K780",
		"type": "Redline",
		"size": "RMS-75/2560",
		"weight": 2934.4
	},
	{
		"manufacturer": "AeroTech",
		"name": "K650",
		"type": "Blue Thunder",
		"size": "RMS-98/2560",
		"weight": 2935.3
	},
	{
		"manufacturer": "AeroTech",
		"name": "K560",
		"type": "White Lightning",
		"size": "RMS-75/2560",
		"weight": 2744
	},
	{
		"manufacturer": "AeroTech",
		"name": "K1050",
		"type": "White Lightning",
		"size": "RMS-54/2800",
		"weight": 2203
	},
	{
		"manufacturer": "AeroTech",
		"name": "K458",
		"type": "White Lightning",
		"size": "RMS-98/2560",
		"weight": 3163.78
	},
	{
		"manufacturer": "AeroTech",
		"name": "K1000",
		"type": "Blue Thunder",
		"size": "RMS-75/2560",
		"weight": 2602
	},
	{
		"manufacturer": "AeroTech",
		"name": "K1999",
		"type": "Warp 9",
		"size": "RMS-98/2560",
		"weight": 2989
	},
	{
		"manufacturer": "AeroTech",
		"name": "L1090",
		"type": "White Lightning",
		"size": "RMS-54/2800",
		"weight": 2432
	},
	{
		"manufacturer": "AeroTech",
		"name": "L1000",
		"type": "White Lightning",
		"weight": 2194
	},
	{
		"manufacturer": "AeroTech",
		"name": "L339",
		"type": "Warp 9",
		"size": "RMS-98/2560",
		"weight": 3210
	},
	{
		"manufacturer": "AeroTech",
		"name": "L1150",
		"type": "Redline",
		"size": "RMS-75/3840",
		"weight": 3673.6
	},
	{
		"manufacturer": "AeroTech",
		"name": "L850",
		"type": "White Lightning",
		"size": "RMS-75/3840",
		"weight": 3742
	},
	{
		"manufacturer": "AeroTech",
		"name": "L1520",
		"type": "Blue Thunder",
		"size": "RMS-75/3840",
		"weight": 3651.4
	},
	{
		"manufacturer": "AeroTech",
		"name": "L1040",
		"type": "Dark Matter",
		"size": "RMS-75/5120",
		"weight": 4717
	},
	{
		"manufacturer": "AeroTech",
		"name": "L1256",
		"type": "Super White Lightning",
		"size": "RMS-75/3840",
		"weight": 3755.7
	},
	{
		"manufacturer": "AeroTech",
		"name": "L900",
		"type": "Dark Matter",
		"size": "RMS-75/5120",
		"weight": 4724
	},
	{
		"manufacturer": "AeroTech",
		"name": "L875",
		"type": "Dark Matter",
		"size": "DMS",
		"weight": 4577
	},
	{
		"manufacturer": "AeroTech",
		"name": "L1390",
		"type": "Mojave Green",
		"size": "RMS-75/3840",
		"weight": 3879
	},
	{
		"manufacturer": "AeroTech",
		"name": "L1170",
		"type": "Black Max",
		"size": "RMS-75/5120",
		"weight": 4990
	},
	{
		"manufacturer": "AeroTech",
		"name": "L1940",
		"type": "Propellant X",
		"size": "RMS-75/3840",
		"weight": 3855.5
	},
	{
		"manufacturer": "AeroTech",
		"name": "L1250",
		"type": "Dark Matter",
		"size": "RMS-75/6400",
		"weight": 5647
	},
	{
		"manufacturer": "AeroTech",
		"name": "L1300",
		"type": "Redline",
		"size": "RMS-98/5120",
		"weight": 4884
	},
	{
		"manufacturer": "AeroTech",
		"name": "L1420",
		"type": "Redline",
		"size": "RMS-75/5120",
		"weight": 4562
	},
	{
		"manufacturer": "AeroTech",
		"name": "L400",
		"type": "White Lightning",
		"size": "RMS-98/5120",
		"weight": 5170
	},
	{
		"manufacturer": "AeroTech",
		"name": "L952",
		"type": "White Lightning",
		"size": "RMS-98/5120",
		"weight": 5012.22
	},
	{
		"manufacturer": "AeroTech",
		"name": "L2500",
		"type": "Super Thunder",
		"size": "RMS-98/5120",
		"weight": 4989
	},
	{
		"manufacturer": "AeroTech",
		"name": "L1365",
		"type": "Metalstorm",
		"size": "RMS-75/5120",
		"weight": 4908
	},
	{
		"manufacturer": "AeroTech",
		"name": "L1500",
		"type": "Blue Thunder",
		"size": "RMS-98/5120",
		"weight": 4659.2
	},
	{
		"manufacturer": "AeroTech",
		"name": "L2200",
		"type": "Mojave Green",
		"size": "RMS-75/5120",
		"weight": 4783
	},
	{
		"manufacturer": "AeroTech",
		"name": "M1350",
		"type": "White Lightning",
		"weight": 4808
	},
	{
		"manufacturer": "AeroTech",
		"name": "M1500",
		"type": "Mojave Green",
		"size": "RMS-75/5120",
		"weight": 4896
	},
	{
		"manufacturer": "AeroTech",
		"name": "M1297",
		"type": "White Lightning",
		"size": "RMS-75/5120",
		"weight": 4637
	},
	{
		"manufacturer": "AeroTech",
		"name": "M2050",
		"type": "Propellant X",
		"size": "RMS-75/5120",
		"weight": 4558.6
	},
	{
		"manufacturer": "AeroTech",
		"name": "M1075",
		"type": "Dark Matter",
		"size": "RMS-98/7680",
		"weight": 6971
	},
	{
		"manufacturer": "AeroTech",
		"name": "M1550",
		"type": "Redline",
		"size": "RMS-75/6400",
		"weight": 5644.8
	},
	{
		"manufacturer": "AeroTech",
		"name": "M1780",
		"type": "New Blue Thunder",
		"size": "RMS-75/5120",
		"weight": 4715
	},
	{
		"manufacturer": "AeroTech",
		"name": "M650",
		"type": "White Lightning",
		"size": "RMS-75/6400",
		"weight": 5125
	},
	{
		"manufacturer": "AeroTech",
		"name": "M1315",
		"type": "White Lightning",
		"size": "RMS-75/6400",
		"weight": 5644.8
	},
	{
		"manufacturer": "AeroTech",
		"name": "M1305",
		"type": "Metalstorm",
		"size": "RMS-98/7680",
		"weight": 7098
	},
	{
		"manufacturer": "AeroTech",
		"name": "M1600",
		"type": "Redline",
		"size": "RMS-98/7680",
		"weight": 6917.12
	},
	{
		"manufacturer": "AeroTech",
		"name": "M4500",
		"type": "Super Thunder",
		"size": "RMS-98/7680",
		"weight": 6622
	},
	{
		"manufacturer": "AeroTech",
		"name": "M1340",
		"type": "White Lightning",
		"weight": 6998
	},
	{
		"manufacturer": "AeroTech",
		"name": "M685",
		"type": "White Lightning",
		"size": "RMS-75/7680",
		"weight": 7008
	},
	{
		"manufacturer": "AeroTech",
		"name": "M1850",
		"type": "White Lightning",
		"size": "RMS-75/7680",
		"weight": 6693
	},
	{
		"manufacturer": "AeroTech",
		"name": "M2400",
		"type": "Blue Thunder",
		"size": "RMS-98/7680",
		"weight": 6451.2
	},
	{
		"manufacturer": "AeroTech",
		"name": "M1419",
		"type": "White Lightning",
		"size": "RMS-98/7680",
		"weight": 6916.22
	},
	{
		"manufacturer": "AeroTech",
		"name": "M2100",
		"type": "Mojave Green",
		"size": "RMS-98/7680",
		"weight": 6918
	},
	{
		"manufacturer": "AeroTech",
		"name": "M1800",
		"type": "Black Max",
		"size": "RMS-98/10240",
		"weight": 9162
	},
	{
		"manufacturer": "AeroTech",
		"name": "M1845",
		"type": "Blue Thunder",
		"size": "RMS-98/7680",
		"weight": 6682
	},
	{
		"manufacturer": "AeroTech",
		"name": "M2000",
		"type": "Redline",
		"size": "RMS-98/10240",
		"weight": 8986.88
	},
	{
		"manufacturer": "AeroTech",
		"name": "M750",
		"type": "White Lightning",
		"size": "RMS-98/10240",
		"weight": 8776
	},
	{
		"manufacturer": "AeroTech",
		"name": "M6000",
		"type": "Super Thunder",
		"size": "RMS-98/10240",
		"weight": 8459
	},
	{
		"manufacturer": "AeroTech",
		"name": "M2500",
		"type": "Blue Thunder",
		"size": "RMS-98/10240",
		"weight": 8064
	},
	{
		"manufacturer": "AeroTech",
		"name": "M1939",
		"type": "White Lightning",
		"size": "RMS-98/10240",
		"weight": 8988.22
	},
	{
		"manufacturer": "AeroTech",
		"name": "N2220",
		"type": "Dark Matter",
		"size": "RMS-98/15360",
		"weight": 11997
	},
	{
		"manufacturer": "AeroTech",
		"name": "N2000",
		"type": "White Lightning",
		"size": "RMS-98/15360",
		"weight": 12282.8
	},
	{
		"manufacturer": "AeroTech",
		"name": "N3300",
		"type": "Redline",
		"size": "RMS-98/15360",
		"weight": 12054
	},
	{
		"manufacturer": "AeroTech",
		"name": "N1000",
		"type": "White Lightning",
		"size": "RMS-98/15360",
		"weight": 12771
	},
	{
		"manufacturer": "AeroTech",
		"name": "O5280",
		"type": "Propellant X",
		"weight": 15680.7
	},
	{
		"manufacturer": "Cesaroni",
		"name": "E22",
		"type": "Smoky Sam",
		"size": "Pro24-1G",
		"weight": 56.5
	},
	{
		"manufacturer": "Cesaroni",
		"name": "E75",
		"type": "Vmax",
		"size": "Pro24-1G",
		"weight": 52
	},
	{
		"manufacturer": "Cesaroni",
		"name": "E31",
		"type": "White Thunder",
		"size": "Pro24-1G",
		"weight": 52
	},
	{
		"manufacturer": "Cesaroni",
		"name": "F36",
		"type": "Smoky Sam",
		"size": "Pro29-1G",
		"weight": 104
	},
	{
		"manufacturer": "Cesaroni",
		"name": "F51",
		"type": "Blue Streak",
		"size": "Pro24-2G",
		"weight": 73
	},
	{
		"manufacturer": "Cesaroni",
		"name": "F36",
		"type": "Blue Streak",
		"size": "Pro29-1G",
		"weight": 101
	},
	{
		"manufacturer": "Cesaroni",
		"name": "F32",
		"type": "White",
		"size": "Pro29-1G",
		"weight": 107
	},
	{
		"manufacturer": "Cesaroni",
		"name": "F70",
		"type": "White Thunder",
		"size": "Pro24-2G",
		"weight": 73
	},
	{
		"manufacturer": "Cesaroni",
		"name": "F29",
		"type": "Imax",
		"size": "Pro29-1G",
		"weight": 105.8
	},
	{
		"manufacturer": "Cesaroni",
		"name": "F31",
		"type": "Classic",
		"size": "Pro29-1G",
		"weight": 102
	},
	{
		"manufacturer": "Cesaroni",
		"name": "F120",
		"type": "Vmax",
		"size": "Pro29-1G",
		"weight": 106.2
	},
	{
		"manufacturer": "Cesaroni",
		"name": "F59",
		"type": "White Thunder",
		"size": "Pro29-1G",
		"weight": 99
	},
	{
		"manufacturer": "Cesaroni",
		"name": "F50",
		"type": "Skidmark",
		"size": "Pro24-3G",
		"weight": 93.9
	},
	{
		"manufacturer": "Cesaroni",
		"name": "F79",
		"type": "Smoky Sam",
		"size": "Pro24-3G",
		"weight": 107.5
	},
	{
		"manufacturer": "Cesaroni",
		"name": "F240",
		"type": "Vmax",
		"size": "Pro24-3G",
		"weight": 91.8
	},
	{
		"manufacturer": "Cesaroni",
		"name": "F30",
		"type": "White Thunder",
		"size": "Pro24-3G",
		"weight": 102.2
	},
	{
		"manufacturer": "Cesaroni",
		"name": "F85",
		"type": "White Thunder",
		"size": "Pro24-3G",
		"weight": 96
	},
	{
		"manufacturer": "Cesaroni",
		"name": "F51",
		"type": "Classic",
		"size": "Pro24-3G",
		"weight": 95
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G88",
		"type": "Smoky Sam",
		"size": "Pro29-2G",
		"weight": 152
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G80",
		"type": "Skidmark",
		"size": "Pro29-2G",
		"weight": 143.2
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G83",
		"type": "Blue Streak",
		"size": "Pro29-2G",
		"weight": 145
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G57",
		"type": "Classic",
		"size": "Pro29-2G",
		"weight": 146
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G68",
		"type": "White",
		"size": "Pro29-2G",
		"weight": 155.9
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G250",
		"type": "Vmax",
		"size": "Pro29-2G",
		"weight": 141.3
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G100",
		"type": "Skidmark",
		"size": "Pro24-6G",
		"weight": 159
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G126",
		"type": "White Thunder",
		"size": "Pro29-2G",
		"weight": 145
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G69",
		"type": "Skidmark",
		"size": "Pro38-1G",
		"weight": 197
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G131",
		"type": "Smoky Sam",
		"size": "Pro29-3G",
		"weight": 200
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G46",
		"type": "Classic",
		"size": "Pro38-1G",
		"weight": 197
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G185",
		"type": "Vmax",
		"size": "Pro38-1G",
		"weight": 188.4
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G79",
		"type": "Smoky Sam",
		"size": "Pro38-1G",
		"weight": 226
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G84",
		"type": "Green3",
		"size": "Pro24-6G",
		"weight": 172
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G60",
		"type": "Red Lightning",
		"size": "Pro38-1G",
		"weight": 206
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G58",
		"type": "White",
		"size": "Pro38-1G",
		"weight": 212.5
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G127",
		"type": "Red Lightning",
		"size": "Pro24-6G",
		"weight": 166
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G106",
		"type": "Skidmark",
		"size": "Pro29-3G",
		"weight": 187
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G107",
		"type": "White Thunder",
		"size": "Pro24-6G",
		"weight": 169.8
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G145",
		"type": "Pink",
		"size": "Pro24-6G",
		"weight": 161.9
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G115",
		"type": "White Thunder",
		"size": "Pro38-1G",
		"weight": 195
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G78",
		"type": "Blue Streak",
		"size": "Pro38-1G",
		"weight": 197
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G117",
		"type": "White Thunder",
		"size": "Pro24-6G",
		"weight": 172.5
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G150",
		"type": "Blue Streak",
		"size": "Pro24-6G",
		"weight": 159.9
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G33",
		"type": "Mellow",
		"size": "Pro29-3G",
		"weight": 197
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G65",
		"type": "White Thunder",
		"size": "Pro24-6G",
		"weight": 174
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G50",
		"type": "Imax",
		"size": "Pro38-1G",
		"weight": 218
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G118",
		"type": "Blue Streak",
		"size": "Pro29-3G",
		"weight": 188
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G54",
		"type": "Red Lightning",
		"size": "Pro29-3G",
		"weight": 192.8
	},
	{
		"manufacturer": "Cesaroni",
		"name": "G125",
		"type": "Red Lightning",
		"size": "Pro29-3G",
		"weight": 194.5
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H133",
		"type": "Blue Streak",
		"size": "Pro29-3G",
		"weight": 190
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H90",
		"type": "Classic",
		"size": "Pro29-3G",
		"weight": 163
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H175",
		"type": "Smoky Sam",
		"size": "Pro29-4G",
		"weight": 247
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H163",
		"type": "White Thunder",
		"size": "Pro29-3G",
		"weight": 187
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H410",
		"type": "Vmax",
		"size": "Pro29-3G",
		"weight": 182.5
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H54",
		"type": "White",
		"size": "Pro29-3G",
		"weight": 209
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H87",
		"type": "Imax",
		"size": "Pro29-3G",
		"weight": 205
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H123",
		"type": "Skidmark",
		"size": "Pro29-4G",
		"weight": 228
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H42",
		"type": "Mellow",
		"size": "Pro29-4G",
		"weight": 242
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H237",
		"type": "Smoky Sam",
		"size": "Pro29-5G",
		"weight": 294
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H151",
		"type": "Red Lightning",
		"size": "Pro29-4G",
		"weight": 238
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H118",
		"type": "Classic",
		"size": "Pro29-4G",
		"weight": 232
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H135",
		"type": "White",
		"size": "Pro29-4G",
		"weight": 251.2
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H170",
		"type": "Blue Streak",
		"size": "Pro29-4G",
		"weight": 232
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H160",
		"type": "Skidmark",
		"size": "Pro29-5G",
		"weight": 272
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H255",
		"type": "White Thunder",
		"size": "Pro29-4G",
		"weight": 232
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H123",
		"type": "Skidmark",
		"size": "Pro38-2G",
		"weight": 297
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H53",
		"type": "Mellow",
		"size": "Pro29-5G",
		"weight": 286
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H143",
		"type": "Smoky Sam",
		"size": "Pro38-2G",
		"weight": 347
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H295",
		"type": "Smoky Sam",
		"size": "Pro29-6G",
		"weight": 342
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H400",
		"type": "Vmax",
		"size": "Pro38-2G",
		"weight": 283.6
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H180",
		"type": "Skidmark",
		"size": "Pro29-6G",
		"weight": 314
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H194",
		"type": "Red Lightning",
		"size": "Pro29-5G",
		"weight": 284
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H120",
		"type": "Red Lightning",
		"size": "Pro38-2G",
		"weight": 295
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H200",
		"type": "Blue Streak",
		"size": "Pro29-5G",
		"weight": 274
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H140",
		"type": "Classic",
		"size": "Pro29-5G",
		"weight": 277
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H110",
		"type": "White",
		"size": "Pro38-2G",
		"weight": 325.3
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H225",
		"type": "White Thunder",
		"size": "Pro38-2G",
		"weight": 193
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H152",
		"type": "Blue Streak",
		"size": "Pro38-2G",
		"weight": 298
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H399",
		"type": "White Thunder",
		"size": "Pro29-6G",
		"weight": 294
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H125",
		"type": "Classic",
		"size": "Pro38-2G",
		"weight": 293
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H100",
		"type": "Imax",
		"size": "Pro38-2G",
		"weight": 327
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H340",
		"type": "Smoky Sam",
		"size": "Pro29-6GXL",
		"weight": 391
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H159",
		"type": "Green3",
		"size": "Pro29-6G",
		"weight": 342.8
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H226",
		"type": "Skidmark",
		"size": "Pro29-6GXL",
		"weight": 360
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H233",
		"type": "Red Lightning",
		"size": "Pro29-6G",
		"weight": 327.6
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H160",
		"type": "Classic",
		"size": "Pro29-6G",
		"weight": 319
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H255",
		"type": "Blue Streak",
		"size": "Pro29-6G",
		"weight": 318
	},
	{
		"manufacturer": "Cesaroni",
		"name": "H565",
		"type": "Vmax",
		"size": "Pro38-3G"
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I180",
		"type": "Skidmark",
		"size": "Pro38-3G",
		"weight": 394
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I204",
		"type": "Imax",
		"size": "Pro29-6G",
		"weight": 349
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I212",
		"type": "Smoky Sam",
		"size": "Pro38-3G",
		"weight": 475
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I195",
		"type": "Red Lightning",
		"size": "Pro38-3G",
		"weight": 417
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I566",
		"type": "Vmax",
		"size": "Pro38-3G",
		"weight": 384.2
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I224",
		"type": "Classic",
		"size": "Pro29-6GXL",
		"weight": 370
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I243",
		"type": "White",
		"size": "Pro29-6GXL",
		"weight": 398.6
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I170",
		"type": "Classic",
		"size": "Pro38-3G",
		"weight": 392
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I55",
		"type": "Mellow",
		"size": "Pro38-3G",
		"weight": 437
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I140",
		"type": "Skidmark",
		"size": "Pro54-1G",
		"weight": 564.3
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I345",
		"type": "White Thunder",
		"size": "Pro38-3G",
		"weight": 395
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I175",
		"type": "White",
		"size": "Pro38-3G",
		"weight": 437.5
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I236",
		"type": "Blue Streak",
		"size": "Pro38-3G",
		"weight": 400
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I800",
		"type": "Vmax",
		"size": "Pro38-4G",
		"weight": 457
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I223",
		"type": "Skidmark",
		"size": "Pro38-4G",
		"weight": 494
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I150",
		"type": "Blue Streak",
		"size": "Pro54-1G",
		"weight": 570
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I445",
		"type": "Vmax",
		"size": "Pro54-1G",
		"weight": 575
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I287",
		"type": "Smoky Sam",
		"size": "Pro38-4G",
		"weight": 605
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I218",
		"type": "White Thunder",
		"size": "Pro54-1G",
		"weight": 580
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I120",
		"type": "Imax",
		"size": "Pro54-1G",
		"weight": 623.2
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I285",
		"type": "Classic",
		"size": "Pro38-4G",
		"weight": 505.90000000000006
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I165",
		"type": "C-Star",
		"size": "Pro54-1G",
		"weight": 594
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I255",
		"type": "Red Lightning",
		"size": "Pro38-4G",
		"weight": 516
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I303",
		"type": "Blue Streak",
		"size": "Pro38-4G",
		"weight": 500
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I470",
		"type": "White Thunder",
		"size": "Pro38-4G",
		"weight": 495
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I242",
		"type": "White",
		"size": "Pro38-4G",
		"weight": 549.9
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I125",
		"type": "White",
		"size": "Pro38-5G",
		"weight": 647
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I350",
		"type": "Smoky Sam",
		"size": "Pro38-5G",
		"weight": 782
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I100",
		"type": "Red Lightning",
		"size": "Pro54-2G",
		"weight": 807
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I540",
		"type": "White Thunder",
		"size": "Pro38-5G"
	},
	{
		"manufacturer": "Cesaroni",
		"name": "I216",
		"type": "Classic",
		"size": "Pro38-5G",
		"weight": 601
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J94",
		"type": "Mellow",
		"size": "Pro38-5G",
		"weight": 660
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J285",
		"type": "Classic",
		"size": "Pro38-5G",
		"weight": 595
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J335",
		"type": "Red Lightning",
		"size": "Pro38-5G",
		"weight": 627
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J270",
		"type": "Green3",
		"size": "Pro38-5G",
		"weight": 654.8
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J316",
		"type": "Pink",
		"size": "Pro38-5G",
		"weight": 607.1
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J357",
		"type": "Blue Streak",
		"size": "Pro38-5G",
		"weight": 601
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J381",
		"type": "Skidmark",
		"size": "Pro38-6G",
		"weight": 688
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J250",
		"type": "Skidmark",
		"size": "Pro54-2G",
		"weight": 830
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J290",
		"type": "White",
		"size": "Pro38-5G",
		"weight": 659.8
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J145",
		"type": "Skidmark",
		"size": "Pro54-2G",
		"weight": 837
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J400",
		"type": "Smoky Sam",
		"size": "Pro38-6G",
		"weight": 702
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J280",
		"type": "Smoky Sam",
		"size": "Pro54-2G",
		"weight": 954
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J1055",
		"type": "Vmax",
		"size": "Pro54-2G",
		"weight": 772
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J330",
		"type": "Classic",
		"size": "Pro38-6G",
		"weight": 702
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J410",
		"type": "Red Lightning",
		"size": "Pro38-6G",
		"weight": 735
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J425",
		"type": "Blue Streak",
		"size": "Pro38-6G",
		"weight": 700
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J240",
		"type": "Red Lightning",
		"size": "Pro54-2G",
		"weight": 875
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J354",
		"type": "White",
		"size": "Pro38-6G",
		"weight": 778.2
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J430",
		"type": "White Thunder",
		"size": "Pro54-2G",
		"weight": 799.8
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J210",
		"type": "Classic",
		"size": "Pro54-2G",
		"weight": 842
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J293",
		"type": "Blue Streak",
		"size": "Pro54-2G",
		"weight": 840
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J520",
		"type": "Skidmark",
		"size": "Pro38-6GXL",
		"weight": 850
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J244",
		"type": "White",
		"size": "Pro54-2G",
		"weight": 911
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J580",
		"type": "Smoky Sam",
		"size": "Pro38-6GXL",
		"weight": 1044
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J150",
		"type": "Mellow",
		"size": "Pro38-6GXL",
		"weight": 951
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J394",
		"type": "Green3",
		"size": "Pro38-6GXL",
		"weight": 938.9
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J595",
		"type": "Blue Streak",
		"size": "Pro38-6GXL",
		"weight": 866
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J600",
		"type": "Red Lightning",
		"size": "Pro38-6GXL",
		"weight": 906
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J420",
		"type": "Classic",
		"size": "Pro38-6GXL",
		"weight": 874
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J453",
		"type": "White",
		"size": "Pro38-6GXL",
		"weight": 964.3000000000001
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J360",
		"type": "Skidmark",
		"size": "Pro54-3G",
		"weight": 1104
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J380",
		"type": "Smoky Sam",
		"size": "Pro54-3G",
		"weight": 1293.3
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J1520",
		"type": "Vmax",
		"size": "Pro54-3G",
		"weight": 1026
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J530",
		"type": "Imax",
		"size": "Pro38-6GXL",
		"weight": 977
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J355",
		"type": "Red Lightning",
		"size": "Pro54-3G",
		"weight": 1175
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J295",
		"type": "Classic",
		"size": "Pro54-3G",
		"weight": 1119
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J140",
		"type": "White",
		"size": "Pro54-3G",
		"weight": 1279.8
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J449",
		"type": "Blue Streak",
		"size": "Pro54-3G",
		"weight": 1122
	},
	{
		"manufacturer": "Cesaroni",
		"name": "J760",
		"type": "White Thunder",
		"size": "Pro54-3G",
		"weight": 1076.8
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K360",
		"type": "White",
		"size": "Pro54-3G",
		"weight": 1232
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K454",
		"type": "Skidmark",
		"size": "Pro54-4G",
		"weight": 1391
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K2045",
		"type": "Vmax",
		"size": "Pro54-4G",
		"weight": 1290
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K530",
		"type": "Smoky Sam",
		"size": "Pro54-4G",
		"weight": 1639.8
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K160",
		"type": "Classic",
		"size": "Pro54-4G",
		"weight": 1472
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K500",
		"type": "Red Lightning",
		"size": "Pro54-4G",
		"weight": 1476
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K400",
		"type": "Green3",
		"size": "Pro54-4G",
		"weight": 1551.3
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K940",
		"type": "White Thunder",
		"size": "Pro54-4G",
		"weight": 1366.5
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K445",
		"type": "Classic",
		"size": "Pro54-4G",
		"weight": 1398
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K515",
		"type": "Skidmark",
		"size": "Pro54-5G",
		"weight": 1654
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K630",
		"type": "Blue Streak",
		"size": "Pro54-4G",
		"weight": 1410
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K520",
		"type": "White",
		"size": "Pro54-4G",
		"weight": 1576
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K650",
		"type": "Smoky Sam",
		"size": "Pro54-5G",
		"weight": 1989.9
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K740",
		"type": "C-Star",
		"size": "Pro54-4G",
		"weight": 1469
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K735",
		"type": "Skidmark",
		"size": "Pro75-2G",
		"weight": 2508.8
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K490",
		"type": "Green3",
		"size": "Pro54-5G",
		"weight": 1854
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K635",
		"type": "Red Lightning",
		"size": "Pro54-5G",
		"weight": 1768
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K650",
		"type": "Pink",
		"size": "Pro54-5G",
		"weight": 1710
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K675",
		"type": "Skidmark",
		"size": "Pro54-6G",
		"weight": 1940
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K1200",
		"type": "White Thunder",
		"size": "Pro54-5G",
		"weight": 1631.9
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K261",
		"type": "White",
		"size": "Pro54-5G",
		"weight": 1931.7
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K570",
		"type": "Classic",
		"size": "Pro54-5G",
		"weight": 1685
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K780",
		"type": "Blue Streak",
		"size": "Pro54-5G",
		"weight": 1700
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K600",
		"type": "White",
		"size": "Pro54-5G",
		"weight": 1892
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K260",
		"type": "Classic",
		"size": "Pro54-6G",
		"weight": 2047.4999999999998
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K815",
		"type": "Skidmark",
		"size": "Pro54-6GXL",
		"weight": 2197
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K2000",
		"type": "Vmax",
		"size": "Pro75-2G",
		"weight": 2464.5
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K750",
		"type": "Red Lightning",
		"size": "Pro54-6G",
		"weight": 2057
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K1440",
		"type": "White Thunder",
		"size": "Pro54-6G",
		"weight": 1892.6
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K711",
		"type": "White",
		"size": "Pro54-6G",
		"weight": 2198
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K820",
		"type": "Blue Streak",
		"size": "Pro54-6G",
		"weight": 1982
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K590",
		"type": "Classic/Dual Thrust",
		"size": "Pro54-6G",
		"weight": 1994
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K555",
		"type": "White",
		"size": "Pro75-2G",
		"weight": 2759
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K1085",
		"type": "White Thunder",
		"size": "Pro75-2G",
		"weight": 2430
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K661",
		"type": "Blue Streak",
		"size": "Pro75-2G",
		"weight": 2527.8
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K660",
		"type": "Classic",
		"size": "Pro54-6G",
		"weight": 1949
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K1620",
		"type": "Vmax",
		"size": "Pro98-1G",
		"weight": 3077
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K510",
		"type": "Classic",
		"size": "Pro75-2G",
		"weight": 2590
	},
	{
		"manufacturer": "Cesaroni",
		"name": "K300",
		"type": "Classic",
		"size": "Pro54-6GXL",
		"weight": 2270
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L265",
		"type": "Mellow",
		"size": "Pro54-6GXL",
		"weight": 2481
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L585",
		"type": "Imax",
		"size": "Pro75-2G",
		"weight": 2784
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L730",
		"type": "Classic",
		"size": "Pro54-6GXL",
		"weight": 2247
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L990",
		"type": "Blue Streak",
		"size": "Pro54-6GXL",
		"weight": 2236
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L640",
		"type": "Classic/Dual Thrust",
		"size": "Pro54-6GXL",
		"weight": 2244
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L1030",
		"type": "Red Lightning",
		"size": "Pro54-6GXL",
		"weight": 2338
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L805",
		"type": "White",
		"size": "Pro54-6GXL",
		"weight": 2502.5
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L910",
		"type": "C-Star",
		"size": "Pro75-2G",
		"weight": 2615.8
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L820",
		"type": "Skidmark",
		"size": "Pro75-3G",
		"weight": 3420
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L935",
		"type": "Imax",
		"size": "Pro54-6GXL",
		"weight": 2542
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L3200",
		"type": "Vmax",
		"size": "Pro75-3G",
		"weight": 3263.7
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L645",
		"type": "Green3",
		"size": "Pro75-3G",
		"weight": 3751.8
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L995",
		"type": "Red Lightning",
		"size": "Pro75-3G",
		"weight": 3591
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L1720",
		"type": "White Thunder",
		"size": "Pro75-3G",
		"weight": 3341
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L851",
		"type": "White",
		"size": "Pro75-3G",
		"weight": 3789
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L1050",
		"type": "Blue Streak",
		"size": "Pro75-3G",
		"weight": 3447.7
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L800",
		"type": "Classic",
		"size": "Pro75-3G",
		"weight": 3511
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L1355",
		"type": "Smoky Sam",
		"size": "Pro75-4G",
		"weight": 4962
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L1350",
		"type": "C-Star",
		"size": "Pro75-3G",
		"weight": 3570.7
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L3150",
		"type": "Vmax",
		"size": "Pro98-2G",
		"weight": 4731
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L1410",
		"type": "Skidmark",
		"size": "Pro75-5G",
		"weight": 5115
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L610",
		"type": "Classic",
		"size": "Pro98-2G",
		"weight": 4975
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L2375",
		"type": "White Thunder",
		"size": "Pro75-4G",
		"weight": 4161
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L1395",
		"type": "Blue Streak",
		"size": "Pro75-4G",
		"weight": 4323
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L395",
		"type": "Mellow",
		"size": "Pro75-5G",
		"weight": 5706
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L1115",
		"type": "Classic",
		"size": "Pro75-4G",
		"weight": 4404
	},
	{
		"manufacturer": "Cesaroni",
		"name": "L1685",
		"type": "Smoky Sam",
		"size": "Pro75-5G",
		"weight": 6051
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1101",
		"type": "White",
		"size": "Pro75-4G",
		"weight": 4938
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1560",
		"type": "White Thunder",
		"size": "Pro98-2G",
		"weight": 4977
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M2250",
		"type": "C-Star",
		"size": "Pro75-4G",
		"weight": 4415
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1230",
		"type": "Imax",
		"size": "Pro75-4G",
		"weight": 4844
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1830",
		"type": "C-Star",
		"size": "Pro75-4G",
		"weight": 4524
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1160",
		"type": "Green3",
		"size": "Pro75-5G",
		"weight": 5698
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1770",
		"type": "Skidmark",
		"size": "Pro75-6G",
		"weight": 5993
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1670",
		"type": "Blue Streak",
		"size": "Pro75-5G",
		"weight": 5231
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M3100",
		"type": "White Thunder",
		"size": "Pro75-5G",
		"weight": 5018
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1810",
		"type": "Red Lightning",
		"size": "Pro75-5G",
		"weight": 5416
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1675",
		"type": "Pink",
		"size": "Pro75-5G",
		"weight": 5223
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1400",
		"type": "Classic",
		"size": "Pro75-5G",
		"weight": 5302
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1401",
		"type": "White",
		"size": "Pro75-5G",
		"weight": 5774
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M2075",
		"type": "Smoky Sam",
		"size": "Pro75-6G",
		"weight": 7191
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1300",
		"type": "Imax/Dual Thrust",
		"size": "Pro75-5G",
		"weight": 5657
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M3700",
		"type": "White Thunder",
		"size": "Pro75-6G",
		"weight": 5778.5
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1540",
		"type": "Imax",
		"size": "Pro75-5G",
		"weight": 5906
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M2080",
		"type": "Skidmark",
		"size": "Pro75-6GXL",
		"weight": 7039.5
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M4770",
		"type": "Vmax",
		"size": "Pro98-3G",
		"weight": 6503
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M2045",
		"type": "Blue Streak",
		"size": "Pro75-6G",
		"weight": 6071
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M520",
		"type": "Classic",
		"size": "Pro98-3G",
		"weight": 6693
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1060",
		"type": "Classic",
		"size": "Pro98-3G",
		"weight": 6673
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M2505",
		"type": "White Thunder",
		"size": "Pro98-3G",
		"weight": 6250
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M2150",
		"type": "Red Lightning",
		"size": "Pro75-6G",
		"weight": 6324
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M840",
		"type": "White",
		"size": "Pro75-6G",
		"weight": 6954
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1590",
		"type": "Classic",
		"size": "Pro75-6G",
		"weight": 6076
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1520",
		"type": "Blue Streak",
		"size": "Pro98-3G",
		"weight": 6718
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1290",
		"type": "White",
		"size": "Pro98-3G",
		"weight": 7411
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1790",
		"type": "Skidmark",
		"size": "Pro98-4G",
		"weight": 8273
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1545",
		"type": "Green3",
		"size": "Pro75-6GXL",
		"weight": 7878.3
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M2020",
		"type": "Imax",
		"size": "Pro75-6G",
		"weight": 7031.799999999999
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M6400",
		"type": "Vmax",
		"size": "Pro98-4G",
		"weight": 7919
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1800",
		"type": "Blue Streak",
		"size": "Pro98-4G",
		"weight": 8342
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1890",
		"type": "Red Lightning",
		"size": "Pro98-4G",
		"weight": 8823
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M1450",
		"type": "Classic",
		"size": "Pro98-4G",
		"weight": 8578
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M2245",
		"type": "Imax",
		"size": "Pro75-6GXL",
		"weight": 8182
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M3400",
		"type": "White Thunder",
		"size": "Pro98-4G",
		"weight": 8108.000000000001
	},
	{
		"manufacturer": "Cesaroni",
		"name": "M795",
		"type": "Classic",
		"size": "Pro98-4G",
		"weight": 8492
	},
	{
		"manufacturer": "Cesaroni",
		"name": "N10000",
		"type": "Vmax",
		"size": "Pro98-6G",
		"weight": 9918.5
	},
	{
		"manufacturer": "Cesaroni",
		"name": "N1800",
		"type": "White",
		"size": "Pro98-4G",
		"weight": 9180
	},
	{
		"manufacturer": "Cesaroni",
		"name": "N2600",
		"type": "Skidmark",
		"size": "Pro98-6G",
		"weight": 11482
	},
	{
		"manufacturer": "Cesaroni",
		"name": "N2200",
		"type": "Pink",
		"size": "Pro98-5G",
		"weight": 11356
	},
	{
		"manufacturer": "Cesaroni",
		"name": "N5600",
		"type": "White Thunder",
		"size": "Pro98-6G",
		"weight": 11280
	},
	{
		"manufacturer": "Cesaroni",
		"name": "N2500",
		"type": "Classic",
		"size": "Pro98-6G",
		"weight": 11668
	},
	{
		"manufacturer": "Cesaroni",
		"name": "N2850",
		"type": "Blue Streak",
		"size": "Pro98-6G",
		"weight": 11688
	},
	{
		"manufacturer": "Cesaroni",
		"name": "N1100",
		"type": "Classic",
		"size": "Pro98-6G",
		"weight": 11589
	},
	{
		"manufacturer": "Cesaroni",
		"name": "N3180",
		"type": "Red Lightning",
		"size": "Pro98-6G",
		"weight": 12198
	},
	{
		"manufacturer": "Cesaroni",
		"name": "N3400",
		"type": "Skidmark",
		"size": "Pro98-6GXL",
		"weight": 13972
	},
	{
		"manufacturer": "Cesaroni",
		"name": "N1975",
		"type": "Green3",
		"size": "Pro98-6G",
		"weight": 13247.5
	},
	{
		"manufacturer": "Cesaroni",
		"name": "N2501",
		"type": "White",
		"size": "Pro98-6G",
		"weight": 13308
	},
	{
		"manufacturer": "Cesaroni",
		"name": "N1560",
		"type": "White",
		"size": "Pro98-6GXL",
		"weight": 15858
	},
	{
		"manufacturer": "Cesaroni",
		"name": "N2900",
		"type": "Classic",
		"size": "Pro98-6GXL",
		"weight": 14166
	},
	{
		"manufacturer": "Cesaroni",
		"name": "N3800",
		"type": "Blue Streak",
		"size": "Pro98-6GXL",
		"weight": 14261
	},
	{
		"manufacturer": "Cesaroni",
		"name": "N4100",
		"type": "Red Lightning",
		"size": "Pro98-6GXL",
		"weight": 14748
	},
	{
		"manufacturer": "Cesaroni",
		"name": "N2540",
		"type": "Green3",
		"size": "Pro98-6GXL",
		"weight": 16280.5
	},
	{
		"manufacturer": "Cesaroni",
		"name": "N3301",
		"type": "White",
		"size": "Pro98-6GXL",
		"weight": 16525
	},
	{
		"manufacturer": "Cesaroni",
		"name": "N5800",
		"type": "C-Star",
		"size": "Pro98-6GXL",
		"weight": 14826
	},
	{
		"manufacturer": "Cesaroni",
		"name": "O3400",
		"type": "Imax",
		"size": "Pro98-6GXL",
		"weight": 16842
	},
	{
		"manufacturer": "Cesaroni",
		"name": "O3700",
		"type": "Skidmark",
		"size": "Pro150-40k",
		"weight": 31350.5
	},
	{
		"manufacturer": "Cesaroni",
		"name": "O5800",
		"type": "White Thunder",
		"size": "Pro150-30K",
		"weight": 26368
	},
	{
		"manufacturer": "Cesaroni",
		"name": "O25000",
		"type": "Vmax",
		"size": "Pro130-",
		"weight": 23558
	},
	{
		"manufacturer": "Cesaroni",
		"name": "O4900",
		"type": "Blue Streak",
		"size": "Pro150-40k",
		"weight": 32648.000000000004
	},
	{
		"manufacturer": "Cesaroni",
		"name": "O8000",
		"type": "White Thunder",
		"size": "Pro150-40k",
		"weight": 32671.999999999996
	}
]