import Page from '../js/page.js';
import * as pageManager from '@trullock/page-manager';
import * as rocketRepository from '../js/rocket-repository.js'

export default pageManager.registerPage({'rockets-add': '/rockets/add'}, class extends Page {
	static requireAuth = true;

	constructor($page) {
		super($page);

		this.$form = $page.$('form');
		this.$form.enableSubmitButtonTracking();

		let $txtWeight = this.$form.$('[name=weight]');

		$page.$('.js-btn-create').addClickListener(e => {
			if (!this.$form.validate())
				return;

			this.$form.setSubmitting(true);
				
			var rocket = {
				id: rocketRepository.newUid(),
				createdOn: new Date(),
				name: this.$form.$('[name="rocket-name"]').value,
				diameter: this.$form.$('[name="diameter"]').value,
				length: this.$form.$('[name="length"]').value,
				weight: $txtWeight.value !== '' ? parseFloat($txtWeight.value) : null,
				buildType: this.$form.$('[name="type"]:checked').value,
				notes: this.$form.$('[name="notes"]').value,
				flights: 0
			}

			rocketRepository.addRocket(rocket).then(r => {
				return pageManager.replace(pageManager.getPath('rockets-view', { rocketId: rocket.id }));
			}).finally(() => {
				this.$form.setSubmitting(false);
			});
		});
	}

	hide(opts)
	{
		this.$form.reset();
		return super.hide();
	}

})