import Page from '../js/page.js';
import {registerPage} from '@trullock/page-manager';

export default registerPage({'error-404': '/error/404'}, class extends Page {

	static existingDomSelector = '#page-error-404';

	constructor($page) {
		super($page);
	}
});