import Page from '../js/page.js';
import { registerPage, getPath } from '@trullock/page-manager';
import * as rocketRepository from '../js/rocket-repository.js'

export default registerPage({'root': '/'}, class extends Page {
	static requireAuth = true;

	constructor($page) {
		super($page);

		this.$tbody = $page.$('table tbody');
		this.$rowTemplate = this.$tbody.firstElementChild.remove();
		this.$noRockets = $page.$('table tfoot');
	}

	boot(opts) {
		return rocketRepository.listRockets(rockets => {
			this.renderRockets(rockets)
		}).then(unsub => this.unsub = unsub);
	}

	destroy() {
		this.unsub?.();
	}

	renderRockets(rockets){

		this.$noRockets.classList.toggle('d-none', rockets.length > 0);

		this.$tbody.empty();
		for(let r = 0; r < rockets.length; r++){
			let $row = this.$rowTemplate.cloneNode(true);
			$row.children[0].textContent = rockets[r].name;
			$row.children[1].textContent = rockets[r].flights;
			$row.$('a').href = getPath('rockets-view', { rocketId: rockets[r].id })
			this.$tbody.appendChild($row);
		}
	}
})