export default class {

	hideOnOverlayClick = true;
	hideOnEscape = true;
	mousedownTarget = null;
	isOpen = false;

	constructor($modal){
		this.$modal = $modal;

		this.$modal.$('.js-close').addClickListener(_ => this.hide());

		document.addEventListener('keyup', e=> {
			if (this.hideOnEscape && e.key == 'Escape' && this.visible)
				this.hide();
		});

		this.$modal.addEventListener('mousedown', e => {
			this.mousedownTarget = e.target;
		});
		this.$modal.addEventListener('click', e => {
			if(this.hideOnOverlayClick && e.target == this.$modal && e.target == this.mousedownTarget)
				this.hide();
		});

		var $iframe = this.$modal.$('iframe');
		if($iframe.length){
			$iframe.addEventListener('load', e=> {
				$iframe.contentWindow.document.addEventListener('keyup', e=> {
					if (e.key == 'Escape' && this.visible)
						this.hide();
				});
			});
		}
	}

	show() {
		if (this.visible)
			return;
			
		this.$modal.classList.add('d-block');
		
		this.$modal.offsetWidth;

		this.$modal.classList.add('show');
		this.visible = true;
		var $firstInput = this.$modal.$('input, select, textarea');
		$firstInput.length && $firstInput.focus();

		this.isOpen = true;
	}

	hide(){
		if(!this.visible)
			return;

		this.visible = false;
		this.$modal.classList.remove('show');

		this.isOpen = false;

		setTimeout(() =>{
			this.$modal.classList.remove('d-block');
		}, 250);
	}
}