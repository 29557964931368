import "regenerator-runtime/runtime.js";

import css from "./scss/theme.scss";
import '@trullock/dollar'

import sitewebmanifest from "./site.webmanifest";
import robotstxt from "./robots.txt"

// because webpack is broken/shit.
import cerebriSans from './fonts/cerebrisans-regular.woff';
window.cerebriSansPath = cerebriSans;

import * as fb from "./js/firebase.js";
await fb.init(firebase);


import init from "./js/index.js";
init();