var functions;

export function init(firebase) {

	// Initialize Firebase
	return fetch('/__/firebase/init.json').then(async response => {
		let config = await response.json();
		config.authDomain = window.location.hostname;

		var app = firebase.initializeApp(config);

		functions = app.functions('europe-west1');
		
		if (location.hostname === 'localhost') {
			firebase.firestore().useEmulator('localhost', 8080);
			firebase.auth().useEmulator('http://localhost:9099', { disableWarnings: true });
			functions.useEmulator("localhost", 5001);
			firebase.storage().useEmulator("localhost", 9199)
		}

		firebase.analytics();
	});
}

export function fx() {
	return functions;
}