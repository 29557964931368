export default class Page {
	
	constructor($page){
		this.$page = $page;
		this.visible = false;
	}

	_title = null;
	get title() {
		return this._title ?? this.$page.dataset['title'] ?? 'FlightLogger'
	}

	set title(value) {
		this._title = value;
	}

	boot(opts){
		return Promise.resolve();
	}

	show(opts) {
		this.$page.dispatchEvent(new CustomEvent('page-shown', {
			bubbles: true, detail: {
				$page: this.$page,
				page: this,
				opts: opts
			}
		}))

		return new Promise(r => {
			if(this.visible)
			{
				r();
			} else {
					this.$page.classList.remove('d-none');
					this.$page.offsetWidth;

					let y = 0;
					if(opts.route.params.hash)
					{
						var $elem = this.$page.$('#' + opts.route.params.hash);
						if($elem)
							y = $elem.offset().top - 30;
						
					}

					window.scroll(0, opts.scrollY || y);

					this.visible = true;
					this.$page.style.opacity = 1;

					window.setTimeout(() => {
						try {
							this.$page.$('[autofocus]:not([disabled])')?.focus();
						} catch (e) { }
						r();
					}, 200);
			}
		});
	}

	hide() {
		return new Promise(r => {
			if(this.visible == false)
			{
				r();
			}
			else
			{
				window.setTimeout(() => {
					this.$page.classList.add('d-none');
					this.visible = false;
					r();
				}, 200);

				this.$page.style.opacity = 0;
			}
		}).then(() => {
			this.$page.dispatchEvent(new CustomEvent('page-hidden', { bubbles: true, detail: {
				$page: this.$page,
				page: this
			}}))
		});
	}
}