import Page from '../js/page.js';
import * as Language from '../js/language.js';
import * as pageManager from '@trullock/page-manager';
import PageShowError from '@trullock/page-manager/pageshowerror'
import {showToast} from '../js/utils.js'
import { countPasswordBreaches } from '../js/hibp.js'

export default pageManager.registerPage({'account-complete-reset-password': '/account/reset-password/complete'}, class extends Page {
	constructor($page) {
		super($page);

		let $form = this.$page.$('form');
		$form.enableSubmitButtonTracking();
		$form.submitHandler(() => {
			let password = $form.querySelector('[name=password]').value;

			countPasswordBreaches(password).then(breaches => {
				if(breaches > 0)
				{
					$form.setValidationResult({ password: Language.validation.messages.breached(breaches)});
					return;
				}

				// https://firebase.google.com/docs/reference/js/firebase.auth.Auth#confirmpasswordreset
				return firebase.auth().confirmPasswordReset(this.code, password)
					.then(() => {
						return firebase.auth().signInWithEmailAndPassword(this.email, password)
							.then(result => {
								showToast({
									style: 'success',
									message: Language.toast['password-changed']
								});

								this.analytics(result);

								return pageManager.navigate('goal', { fallback: this.goalUrl });
							}, e => {
								console.error(e);
								pageManager.replace(pageManager.getPath('error'));
							})
					})
					.catch(function (error) {
						if (error.code == 'auth/weak-password')
							$form.setValidationResult(Language.validation[error.code] || Language.validation['error']);
						else
						{
							console.error(error);
							pageManager.show(pageManager.getPath('account-complete-reset-password-error'));
						}
					})
				})
				.then(() => $form.setSubmitting(false));
		});
	}

	analytics(userCredential){
		firebase.analytics().logEvent('login', {
			method: 'reset-password'
		});
	}

	show(opts){
		if(!opts.oobCode)
			return Promise.reject(new PageShowError(pageManager.getPath('account-complete-reset-password-error'), "Missing oobCode"));
			
		this.code = opts.oobCode;

		if(opts.continueUrl){
			// firebase should enforce same-origin, but we should also check
			var schemeAndHost = window.location.protocol + '//' + window.location.host;
			if(opts.continueUrl.substr(0, schemeAndHost.length).toLowerCase() === schemeAndHost.toLowerCase())
				this.goalUrl = opts.continueUrl.substr(schemeAndHost.length);
		}
		
		var soup = () => super.show(opts);

		return firebase.auth().verifyPasswordResetCode(this.code)
			.then(email => {
				this.email = email;
				return soup();
			})
			.catch(e => {
				throw new PageShowError(pageManager.getPath('account-complete-reset-password-error'), 'Error verifying password reset code. ' + e);
			});
	}
})