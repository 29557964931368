import Page from '../js/page.js';
import {registerPage} from '@trullock/page-manager';

export default registerPage({'error': '/error'}, class extends Page {

	static existingDomSelector = '#page-error';

	constructor($page) {
		super($page);
	}
});