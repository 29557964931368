import {onSnapshot} from '@trullock/firebase'

function hydrateRocket(data) {
	if(!data)
		return null;
	let createdOn = new Date();
	createdOn.setTime(data.createdOn);

	return {
		id: data.id,
		createdOn: createdOn,
		name: data.name,
		diameter: data.diameter,
		length: data.length,
		weight: data.weight,
		buildType: data.buildType,
		notes: data.notes,
		flights: data.flights || 0
	}
}

function dehydrateRocket(rocket) {
	return {
		id: rocket.id,
		createdOn: rocket.createdOn.getTime(),
		name: rocket.name,
		diameter: rocket.diameter,
		length: rocket.length,
		weight: rocket.weight,
		buildType: rocket.buildType,
		notes: rocket.notes
	}
}

function hydrateFlight(data) {
	if (!data)
		return null;

	let createdOn = new Date();
	createdOn.setTime(data.createdOn);

	let occurredOn = new Date();
	occurredOn.setTime(data.occurredOn);
	
	return {
		id: data.id,
		createdOn: createdOn,
		occurredOn: occurredOn,
		location: data.location,
		locationId: data.locationId,
		latitude: data.latitude,
		longitude: data.longitude,
		motor: data.motor,
		delay: data.delay,
		weight: data.weight,
		stability: data.stability,
		landingLatitude: data.landingLatitude,
		landingLongitude: data.landingLongitude,
		outcome: data.outcome,
		notes: data.notes,
		certification: data.certification,
		certificationOutcome: data.certificationOutcome
	};
}

function dehydrateFlight(flight) {
	
	let obj = {
		id: flight.id,
		createdOn: flight.createdOn.getTime(),
		occurredOn: flight.occurredOn.getTime(),
		location: flight.location,
		locationId: flight.locationId,
		latitude: flight.latitude,
		longitude: flight.longitude,
		motor: flight.motor,
		delay: flight.delay,
		weight: flight.weight,
		stability: flight.stability,
		notes: flight.notes ?? null,
		certification: flight.certification ?? null
	};

	if(flight.landingLatitude !== undefined)
		obj.landingLatitude = flight.landingLatitude;
	if (flight.landingLongitude !== undefined)
		obj.landingLongitude = flight.landingLongitude;
	if(flight.outcome !== undefined)
		obj.outcome = flight.outcome;
	if (flight.certificationOutcome !== undefined)
		obj.certificationOutcome = flight.certificationOutcome;
	
	return obj;
}
/**
 * Generates a new Uid
 * @returns {string} a new Uid
 */
export function newUid() {
	return firebase.firestore().collection("placeholder").doc().id
}

/**
 * Adds a new rocket to the current user's collection
 * @param {object} rocket 
 * @returns 
 */
export function addRocket(rocket) {
	return firebase.firestore()
		.collection('users')
		.doc(firebase.auth().currentUser.uid)
		.collection("rockets")
		.doc(rocket.id)
		.set(dehydrateRocket(rocket));
}

/**
 * Updates the outcome of a flight
 * @param {string} rocketId
 * @param {string} flightId
 * @param {object} outcome
 * @returns 
 */
export function setFlightOutcome(rocketId, flightId, outcome) {
	return firebase.firestore()
		.collection('users')
		.doc(firebase.auth().currentUser.uid)
		.collection("rockets")
		.doc(rocketId)
		.collection("flights")
		.doc(flightId)
		.update(outcome);
}

/**
 * Adds a new flight to the given rocket owned by the current user
 * @param {string} rocketId
 * @param {object} flight 
 * @returns 
 */
export function saveFlight(rocketId, flight) {
	return firebase.firestore()
		.collection('users')
		.doc(firebase.auth().currentUser.uid)
		.collection("rockets")
		.doc(rocketId)
		.collection("flights")
		.doc(flight.id)
		.set(dehydrateFlight(flight), { merge: true });
}
/**
 * Gets a rocket and its flights by ID for the current user
 * @param {string} rocketId the ID of the rocket to get
 * @param {string} flightId the ID of the rocket flight to get
 * @param {function} listenCallback callback to execute on each snapshot update
 * @returns {Promise} A promise that resolves with the listener unsubscribe function as its result
 */
export function getRocketAndFlight(rocketId, flightId, listenCallback) {
	return onSnapshot([
		() =>
			firebase.firestore()
				.collection('users')
				.doc(firebase.auth().currentUser.uid)
				.collection('rockets')
				.doc(rocketId)
		, () =>
			firebase.firestore()
				.collection('users')
				.doc(firebase.auth().currentUser.uid)
				.collection('rockets')
				.doc(rocketId)
				.collection('flights')
				.doc(flightId)
	], (rocketSnap, flightSnap) => {
		let rocket = hydrateRocket(rocketSnap?.data());
		let flight = hydrateFlight(flightSnap?.data());
		listenCallback(rocket, flight);
	})
}

/**
 * Gets a rocket and its flights by ID for the current user
 * @param {string} rocketId the ID of the rocket and its flights to get
 * @param {function} listenCallback callback to execute on each snapshot update
 * @returns {Promise} A promise that resolves with the listener unsubscribe function as its result
 */
export function getRocketWithFlights(rocketId, listenCallback) {
	return onSnapshot([
		() => 
			firebase.firestore()
				.collection('users')
				.doc(firebase.auth().currentUser.uid)
				.collection('rockets')
				.doc(rocketId)
		, () =>
			firebase.firestore()
				.collection('users')
				.doc(firebase.auth().currentUser.uid)
				.collection('rockets')
				.doc(rocketId)
				.collection('flights')
				.orderBy('createdOn', 'desc')
		], (rocketSnap, flightsSnap) => {
			let rocket = hydrateRocket(rocketSnap?.data());
			let flights = flightsSnap ? flightsSnap.docs.map(doc => hydrateFlight(doc.data())) : [];
			listenCallback(rocket, flights);
		})
}

/**
 * Gets a rocket by ID for the current user
 * @param {string} rocketId the ID of the rocket to get
 * @param {function} listenCallback callback to execute on each snapshot update
 * @returns {Promise} A promise that resolves with the listener unsubscribe function as its result
 */
export function getRocket(rocketId, listenCallback) {
	return onSnapshot(() =>
		firebase.firestore()
			.collection('users')
			.doc(firebase.auth().currentUser.uid)
			.collection('rockets')
			.doc(rocketId),
		snap => listenCallback(hydrateRocket(snap.data())))
}

/**
 * Lists all rockets for the current user
 * @param {function} listenCallback callback to execute on each snapshot update
 * @returns {Promise} A promise that resolves with the listener unsubscribe function as its result
 */
export function listRockets(listenCallback) {
	return onSnapshot(() =>
		firebase.firestore()
			.collection('users')
			.doc(firebase.auth().currentUser.uid)
			.collection('rockets'),
		snap => listenCallback(snap.docs.map(d => hydrateRocket(d.data()))))
}

/**
 * Deletes a rocket with the given id owned by the current user
 * @param {string} id 
 * @returns {Promise}
 */
export function deleteRocket(id) {
	// TODO: does this cascade to flights?
	return firebase.firestore()
		.collection('users')
		.doc(firebase.auth().currentUser.uid)
		.collection("rockets")
		.doc(id)
		.delete();
}

/**
 * Deletes a flight with the given id for the given rocket id owned by the current user
 * @param {string} rocketId
 * @param {string} flightId
 * @returns {Promise}
 */
export function deleteFlight(rocketId, flightId) {
	// TODO: does this cascade to flights?
	return firebase.firestore()
		.collection('users')
		.doc(firebase.auth().currentUser.uid)
		.collection("rockets")
		.doc(rocketId)
		.collection("flights")
		.doc(flightId)
		.delete();
}