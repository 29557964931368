import Page from '../js/page.js';
import {registerPage} from '@trullock/page-manager';

export default registerPage({'account-preferences': '/account/preferences'}, class extends Page {
	static requireAuth = true;

	constructor($page) {
		super($page);
		
		let $form = this.$page.$('form');
		this.$currentEmail = this.$page.$('.js-current-email');
		this.$txtEmail = $form.$('[name=email]');

		$form.submitHandler(() => {

			// TODO: implement 
			$form.setSubmitting(false);
		});
	}

	show(opts){
		this.$currentEmail.textContent = firebase.auth().currentUser.email;

		return super.show(opts);
	}
})